import { EventEmitter, Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { HTTPStatusCode } from '@enums/statusCodes';
import { BimObjectEmbedData, BimObjectEmbedDataFile, CatalogLanguage, Client as CatalogPublicAPI } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { firstValueFrom } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
	providedIn: 'root'
})
export class BimobjectService {

	private static currentBimObjectData: { gtin: string; value: BimObjectEmbedData };
	private static currentGtin: string;
	private static currentCatalogLanguage: CatalogLanguage;
	private static openDialog = new EventEmitter();

	constructor(private catalogPublicAPI: CatalogPublicAPI,
		private requestService: RequestService) { }



	async getBimObjectViewer(): Promise<BimObjectEmbedData> {
		try {
			if (!isNullish(BimobjectService.currentGtin) && BimobjectService.currentGtin === BimobjectService.currentBimObjectData?.gtin) {
				return BimobjectService.currentBimObjectData.value;
			}
			const result = await firstValueFrom(this.catalogPublicAPI.catalogView_GetBimObjectViewerSource(BimobjectService.currentGtin, BimobjectService.currentCatalogLanguage));
			BimobjectService.currentBimObjectData = {
				gtin: BimobjectService.currentGtin, value: result
			}
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	async getBimObjectFiles(): Promise<BimObjectEmbedDataFile[]> {
		if (BimobjectService.currentGtin !== BimobjectService.currentBimObjectData?.gtin) {
			await this.getBimObjectViewer();
		}
		return BimobjectService.currentBimObjectData.value.files;
	}

	public setBimObjectConfig(gtin: string, language: CatalogLanguage) {
		BimobjectService.currentCatalogLanguage = language;
		BimobjectService.currentGtin = gtin;
	}

	public get openDialog() {
		return BimobjectService.openDialog;
	}
}
