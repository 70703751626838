import { Injectable } from '@angular/core';
import { Client as AnalyticsTrackingApi, TrackAttachmentDto, TrackCatalogDto, TrackCatalogExternalDto, TrackCatalogItemDto, TrackCatalogListingDto, TrackCatalogSuggestionDto, TrackContactFormOpenDto, TrackContactFormSubmitDto, TrackExportDto, TrackLoginRequestDto, TrackLoginSuccessDto, TrackSearchCatalogDto, TrackSearchGlobalDto, TrackShareCatalogItemDto } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { RequestService } from './request.service';


@Injectable({
	providedIn: 'root'
})
export class TrackEventService {

	constructor(
		private analyticsTrackingApi: AnalyticsTrackingApi,
		private requestService: RequestService
	) { }

	public async trackExport(trackExportDto: TrackExportDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateTrackingExport(trackExportDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackExports(trackExportDtos: TrackExportDto[]) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateTrackingExports(trackExportDtos)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackLoginRequest(trackLoginRequestDto: TrackLoginRequestDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateLoginRequest(trackLoginRequestDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackLoginSuccess(trackLoginSuccessDto: TrackLoginSuccessDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateLoginSuccess(trackLoginSuccessDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackCatalogListing(trackCatalogListingDto: TrackCatalogListingDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalogListing(trackCatalogListingDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackCatalogSuggestion(trackCatalogSuggestionDto: TrackCatalogSuggestionDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalogSuggestion(trackCatalogSuggestionDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackCatalog(trackCatalogDto: TrackCatalogDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalog(trackCatalogDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackCatalogItem(trackCatalogItemDto: TrackCatalogItemDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalogItem(trackCatalogItemDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackAttachment(trackAttachmentDto: TrackAttachmentDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateAttachment(trackAttachmentDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackSearchGlobal(trackSearchGlobalDto: TrackSearchGlobalDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateGlobalSearch(trackSearchGlobalDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackSearchCatalog(trackSearchCatalogDto: TrackSearchCatalogDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalogSearch(trackSearchCatalogDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackContactFormOpen(trackContactFormOpenDto: TrackContactFormOpenDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateContactFormOpen(trackContactFormOpenDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackContactFormSubmit(trackContactFormSubmitDto: TrackContactFormSubmitDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateContactFormSubmit(trackContactFormSubmitDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackShareCatalogItem(trackShareCatalogItemDto: TrackShareCatalogItemDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateShareCatalogItem(trackShareCatalogItemDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async trackCatalogExternal(trackCatalogExternalDto: TrackCatalogExternalDto) {
		try {
			await this.analyticsTrackingApi.trEvent_CreateCatalogExternal(trackCatalogExternalDto)
				.toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

}
