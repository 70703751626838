<div class="grid-catalog-container d-flex flex-column h-100" [class.vw-100]="currentViewType === viewType.Mobile"
	*ngIf="mainViewer && !isContentLoading; else loading">
	<div *ngIf="catalogIntegrationType === CatalogIntegrationType.CatalogItemViewer">
		<ng-content></ng-content>
	</div>
	<div [class.d-none]="currentViewType !== viewType.Desktop || catalogIntegrationType === CatalogIntegrationType.CatalogItemViewer"
		class="flex-grow-1">
		<div class="h-auto px-2 px-sm-3 px-xl-4 pt-2 pt-xl-3">
			<div class="d-flex justify-content-between">
				<app-title-viewer [showWatchlistIcon]="true">
				</app-title-viewer>
			</div>
		</div>
	</div>

	<div class="h-100 w-100" [ngClass]="currentViewType === viewType.Desktop?'overflow-hidden':'scrollbox-autohover'">
		<div [class.scrollbox-content-autohover]="currentViewType !== viewType.Desktop"
			[class.h-100]="currentViewType === viewType.Desktop || getTabValue() !== tab.Overview"
			class=" d-flex flex-column bg-white border-catalog-details">
			<h1 *ngIf="currentViewType !== viewType.Desktop" class="fw-bold lh-normal text-normal mb-0 mx-2 mx-sm-3">
				{{mobileHeader}}
			</h1>
			<!-- CSS-Grid Action Buttons -->
			<div class="flex-grow-1">
				<div class="h-auto p-2 p-sm-3 p-xl-4 pb-3 pb-xl-4">
					<app-action-buttons [catalogIntegration]="catalogIntegrationType" [catalogDetailsType]="catalogDetailsType"
						[routingParams]="currentRoutingValues" [isFolderCatalogItem]="isFolderCatalogItem"
						[selectedItem]="currentMainAttachment" [catalogDetailsType]="catalogDetailsType" [exportSettings]="exportSettings"
						(contactFormOpen)="openContactForm($event)">
					</app-action-buttons>
				</div>
			</div>

			<!-- CSS-Grid Details -->
			<div [ngClass]="currentViewType === viewType.Desktop?'overflow-hidden':'vw-100 mobile-min-height'"
				[class.detail-scroll]="getTabValue() !== tab.Overview" class="h-100 ">
				<div class="d-flex flex-column h-100 px-2 px-sm-3 px-xl-4">
					<!-- Tabreiter Anzeige -->
					<div class="flex-grow-1">
						<div class="h-auto">
							<app-tabviewer #tabViewer [tabs]="tabsViewModel" [catalogDetailsType]="catalogDetailsType"
								[queryParams]="queryParams" [routingParams]="routingParams" [viewGroupDtos]="viewGroupDtos">
							</app-tabviewer>
						</div>
					</div>
					<div [id]="tabViewer.navId" [class.overflow-hidden]="currentViewType=== viewType.Desktop"
						class="h-100 d-flex flex-column">
						<app-catalog-content [type]="overviewType" [attachment]="currentMainAttachment" [mainViewer]="mainViewer"
							[isSearchMode]="routingParams?.view === 'search'" [attachments]="viewGroupItems"
							[catalogViewShowPrice]="catalogViewShowPrice"
							*ngIf="getTabValue() === tab.Overview && mainViewer === Viewer.HtmlViewer">
						</app-catalog-content>
						<div *ngIf="getTabValue() !== tab.Overview" id="catalog-details-scroller"
							[class.scrollbox-autohover]="currentViewType=== viewType.Desktop" class="h-100 pt-3">
							<!--minheight muss noch nachträglich gesetzt werden damit bei ganz kleinen Höhen der Kontent nicht verloren geht-->
							<div [class.scrollbox-content-autohover]="currentViewType=== viewType.Desktop"
								class="d-flex flex-column h-100 ">
								<div class="d-flex flex-column h-100">
									<div class="h-100">
										<app-content-viewer [mainViewer]="mainViewer" [catalogViewService]="getCatalogViewService()"
											[catalogKey]="catalogKey" [catalogLanguage]="catalogLanguage"
											[catalogItemGuid]="catalogItemGuid" [attachment]="currentMainAttachment"
											[attachments]="viewGroupItems" [featureToggles]="featureToggles"
											[catalogContentViewersViewModel]="catalogContentViewersViewModel"
											[routingParams]="routingParams" [url]="url" [queryParams]="queryParams"
											[catalogDetailsType]="catalogDetailsType" (contactFormOpen)="openContactForm($event)">
										</app-content-viewer>
									</div>
								</div>
								<div *ngIf="hasThumbnails" class="flex-grow-1">
									<app-attachment-thumbnails [routingParams]="routingParams" [catalogDetailsType]="catalogDetailsType"
										[currentViewType]="currentViewType" [viewGroupItems]="viewGroupItems">
									</app-attachment-thumbnails>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #loading>
	<app-loading></app-loading>
</ng-template>