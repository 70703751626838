import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass]
})
export class LoadingComponent implements OnInit {
	@Input() color = 'primary';
	@Input() size;
	constructor() { }

	ngOnInit(): void {
	}

	public get colorClass() {
		return `text-${this.color}`
	}

}
