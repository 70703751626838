// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 

import { CommonModule } from '@angular/common';
import { Component, ComponentRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogViewCatalogInformationDto } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogQueryParams } from '@interfaces/iCatalogRoutingParams';
import { CatalogViewService } from '@services/catalog-view.service';
import { LogoService } from '@services/logo.service';
import { TranslationService } from '@services/translation.service';
import { Subscription } from 'rxjs';
import { MobileBarComponent, MobileButtonType } from "../../../components/mobile-bar/mobile-bar.component";
import { MobileHeaderComponent } from "../../header/mobile-header/mobile-header.component";

@Component({
	selector: 'app-catalog-mobile',
	standalone: true,
	templateUrl: './catalog-mobile.component.html',
	styleUrls: ['./catalog-mobile.component.scss'],
	providers: [CatalogViewService],
	imports: [CommonModule, RouterModule, MobileBarComponent, MobileHeaderComponent]
})
export class CatalogMobileComponent implements OnInit, OnDestroy {
	public catalogKey: string;
	private currentMobileView: MobileButtonType;
	public catalog: CatalogViewCatalogInformationDto;
	private componentRef: ComponentRef<any>
	public mobileBarButtons = [MobileButtonType.CatalogTree, MobileButtonType.CatalogInfo, MobileButtonType.CatalogDetails, MobileButtonType.SearchInput];
	public disabledMobileBarButtons = [];
	public initMobileBarButton = MobileButtonType.CatalogDetails;
	private routeSubscription: Subscription;
	private queryParamSubscription: Subscription;
	@ViewChild("viewer", { static: true, read: ViewContainerRef }) viewer: ViewContainerRef;
	@ViewChild("mobileBar", { static: true }) mobileBar: MobileBarComponent;


	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private catalogViewService: CatalogViewService,
		private titleService: Title,
		private meta: Meta,
		private translationService: TranslationService,
		private logoService: LogoService
	) {
	}

	async ngOnInit() {
		this.setInitView();
		const defaultButtons = this.mobileBarButtons;
		if (isNullish(this.route.snapshot.params.catalogItemGuid) && MobileButtonType[this.route.snapshot.queryParams.mobileView as string] === MobileButtonType.CatalogDetails) {
			// Ausnahmefall: Nutzer wählt Detailansicht aus, wechselt dann in den Desktopmodus, wählt den Wurzelknoten aus und wechselt dann wieder in den Mobilemodus
			// Zuvor gewählte Mobilansicht wird dann gelöscht 
			await this.router.navigate(
				[],
				{
					relativeTo: this.route,
					queryParams: { mobileView: null },
					queryParamsHandling: 'merge',
				}
			);
		}

		this.routeSubscription = this.route.params.subscribe(async params => {
			this.catalogKey = params.key;
			this.meta.updateTag({ name: 'image', content: this.logoService.getLargeLogosByKey(this.catalogKey).optimizedSrc });
			this.componentRef?.setInput("catalogKey", this.catalogKey);
			this.disabledMobileBarButtons = isNullish(params.catalogItemGuid) ? [MobileButtonType.CatalogDetails] : [];
			this.catalog = await this.catalogViewService.getCatalogInformation(this.catalogKey);
			if (!isNullish(this.catalog)) {
				if (isNullish(params.catalogItemGuid)) {
					this.titleService.setTitle(this.translationService.getByKey("browsertab.catalog-view", { name: this.catalog.title }));
					this.meta.updateTag({ name: 'title', content: this.catalog.title });
					this.meta.updateTag({ name: 'description', content: this.catalog.catalogMetaDescription });
				} else {
					void await this.catalogViewService.getCatalogItemMeta(params.catalogItemGuid).then(catalogItemMetaData => {
						if (!isNullish(catalogItemMetaData) && this.route.snapshot.params.catalogItemGuid === catalogItemMetaData.guid) {
							const title = catalogItemMetaData.title;
							this.titleService.setTitle(`${this.catalog.title} - ${title}`);
							this.meta.updateTag({ name: 'title', content: title });
							this.meta.updateTag({ name: 'description', content: catalogItemMetaData.description });
						}
					});

				}
				const additionalButtons = [];
				if (this.catalog.hasContactPersons) {
					additionalButtons.push(MobileButtonType.CatalogContact);
				}
				if (this.catalog.hasNews) {
					additionalButtons.push(MobileButtonType.Infocenter);
				}
				this.mobileBarButtons = defaultButtons.concat(additionalButtons)
				await this.translationService.switchCatalogLanguage(this.catalog.language);
				if (!isNullish(this.catalog)) {
					this.componentRef?.setInput("catalog", this.catalog);

				}
			}


		});

		this.queryParamSubscription = this.route.queryParams.subscribe(async (params: ICatalogQueryParams) => {
			if (isNullish(this.route.snapshot.queryParams.mobileView)) {
				this.setInitView();
			} else {
				const currentMobileView = MobileButtonType[params.mobileView];
				if (isNullish(this.currentMobileView) || currentMobileView !== this.currentMobileView) {
					await this.setCurrentView(this.currentMobileView);
				}
			}


		})
	}

	private setInitView() {
		if (isNullish(this.route.snapshot.queryParams.mobileView)) {
			if (isNullish(this.route.snapshot.params.catalogItemGuid)) {
				this.mobileBar.buttonClicked(MobileButtonType.CatalogInfo)
			} else {
				this.mobileBar.buttonClicked(MobileButtonType.CatalogDetails)
			}
		}
	}

	private async getMobileComponent() {

		switch (this.currentMobileView) {

			case MobileButtonType.CatalogTree: {
				return this.route.snapshot.params.view === "search" ?
					(await import('../catalog-mobile-search-tree/catalog-mobile-search-tree.component')).CatalogMobileSearchTreeComponent :
					(await import('../catalog-mobile-tree/catalog-mobile-tree.component')).CatalogMobileTreeComponent;
			}
			case MobileButtonType.CatalogContact:
			case MobileButtonType.CatalogInfo: {
				return (await import('../catalog-mobile-info/catalog-mobile-info.component')).CatalogMobileInfoComponent;
			}
			case MobileButtonType.CatalogDetails: {

				return (await import('../catalog-mobile-details/catalog-mobile-details.component')).CatalogMobileDetailsComponent;
			}
			case MobileButtonType.SearchInput: {
				return (await import('../catalog-mobile-search/catalog-mobile-search.component')).CatalogMobileSearchComponent;
			}
			case MobileButtonType.Infocenter: {
				return (await import('../catalog-mobile-news/catalog-mobile-news.component')).CatalogMobileNewsComponent;
			}
		}
	}

	async setCurrentView(type: MobileButtonType) {
		this.currentMobileView = type;
		const component = await this.getMobileComponent();
		if (!isNullish(component)) {
			this.viewer.clear();
			this.componentRef = this.viewer.createComponent(component as any);
			this.componentRef?.setInput("catalogKey", this.catalogKey);
			if (!isNullish(this.catalog)) {
				this.componentRef?.setInput("catalog", this.catalog);
			}
		}


	}



	async ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
		this.queryParamSubscription?.unsubscribe();
	}

}
