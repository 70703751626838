import { NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { isNullish } from "@commonHelpers/math-utils";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { CatalogTreeService } from "@services/catalog-tree.service";
import { WatchlistActionService } from "@services/watchlist-action.service";
import { HasMarkedTextDirective } from "../../directives/has-marked-text.directive";

@Component({
	selector: "app-title-viewer",
	templateUrl: "./title-viewer.component.html",
	styleUrls: ["./title-viewer.component.scss"],
	standalone: true,
	imports: [HasMarkedTextDirective, NgIf]
})
export class TitleViewerComponent implements OnInit {


	public title: string;
	@Input() showWatchlistIcon = false
	@Input() inputTitle: string;


	constructor(
		private router: ActivatedRoute,
		private watchlistActionService: WatchlistActionService,
		private catalogTreeService: CatalogTreeService
	) { }

	ngOnInit() {
		if (isNullish(this.inputTitle)) {
			this.router.params.subscribe(async (params: ICatalogRoutingParams) => {
				if (!isNullish(params.key) && (isNullish(params.catalogItemGuid))) {
					this.title = '';
				} else {
					const node = await this.catalogTreeService.getCurrentNode(params);
					this.title = node?.text
				}
			}
			)
		} else {
			this.title = this.inputTitle;
		}
	}

	public get isInWatchlist() {
		if (isNullish(this.router.snapshot.params.watchlistSection)) {
			return false;
		}
		const marked = this.watchlistActionService.isMarked(this.router.snapshot.params.catalogItemGuid);
		return this.showWatchlistIcon && (marked.isMarked || marked.isPartlyMarked);
	}

	public get watchlistIcon() {
		const marked = this.watchlistActionService.isMarked(this.router.snapshot.params.catalogItemGuid);
		return `assets/orca/watchlist/${marked.isMarked ? 'gemerkt.svg' : marked.isPartlyMarked ? 'halb-gemerkt.svg' : ''}`;
	}
}
