/** *************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import { CommonModule } from "@angular/common";
import { HttpClient, HttpRequest, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, enableProdMode, importProvidersFrom } from "@angular/core";
import '@angular/localize/init';
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { provideAnimations } from "@angular/platform-browser/animations";
import { interactionType, interceptorProvider, loginLanguage, profEditAuthority, protectedResourceMap, pwdResetAuthority, scopes, signinAuthority } from "@app/msal/msalb2c.config";
import { SafeHtmlPipe } from "@app/pipes/safeHtml";
import { CatalogMobileDetailsComponent } from "@app/public/catalog/catalog-mobile-details/catalog-mobile-details.component";
import { CatalogMobileInfoComponent } from "@app/public/catalog/catalog-mobile-info/catalog-mobile-info.component";
import { CatalogMobileComponent } from "@app/public/catalog/catalog-mobile/catalog-mobile.component";
import { CatalogComponent } from "@app/public/catalog/catalog.component";
import { MsalInterceptorAuthRequest, MsalModule, MsalService } from "@commonNodeModules/@azure/msal-angular";
import { BrowserCacheLocation, LogLevel, PublicClientApplication } from "@commonNodeModules/@azure/msal-browser";
import { TranslateCompiler, TranslateLoader, TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { TranslateHttpLoader } from "@commonNodeModules/@ngx-translate/http-loader";
import { TranslateMessageFormatCompiler } from "@commonNodeModules/ngx-translate-messageformat-compiler";
import { MsalB2CService } from "@commonServices/msalb2c.service";
import { CatalogInfoViewerComponent } from "@app/viewers/cataloginfoviewer/cataloginfoviewer.component";
import { HtmlviewerComponent } from "@app/viewers/htmlviewer/htmlviewer.component";
import { environment } from "@environments/environment";
import { globalEnvironment } from "@environments/globalEnvironment";
import { Client as AccountPublicApi } from "@interfaces/HttpClient/AccountApiPublicModels";
import { Client as AnalyticsTrackingApi } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { Client as CatalogPublicAPI } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ApplicationInsightsService } from "@services/application-insights.service";
import { CacheService } from "@services/cache.service";
import { CssVariableService } from "@services/css-variable.service";
import { ErrorHandlerService } from "@services/error-handler.service";
import { GlobalVarService } from "@services/global-var.service";
import { IntervalService } from "@services/interval.service";
import { SoftwareIntegrationCallbackService } from "@services/software-integration-callback.service";
import { TrackEventService } from "@services/track-event.service";
import { TranslationService } from "@services/translation.service";
import { UserAuthorizationService } from "@services/user-authorization.service";
import { ViewTypeService } from "@services/view-type.service";
import { WatchlistActionService } from "@services/watchlist-action.service";
import { WatchlistService } from "@services/watchlist.service";
import { GoogleTagManagerModule } from "angular-google-tag-manager";
import { AppRoutingModule } from "./app/app-routing.module";
import { AppComponent } from "./app/app.component";

export const getBaseUrl = () => document.getElementsByTagName("base")[0].href

const providers = [{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		providers,
		CatalogComponent,
		CatalogMobileComponent,
		CatalogMobileDetailsComponent,
		CatalogMobileInfoComponent,
		CatalogInfoViewerComponent,
		HtmlviewerComponent,
		importProvidersFrom(AppRoutingModule, BrowserModule, CommonModule, SafeHtmlPipe, TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/', '.json?buildDate=' + (new Date(globalEnvironment.buildDate))?.getTime()),
				deps: [HttpClient]
			},
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			}
		}), MsalModule.forRoot(new PublicClientApplication({
			auth: {
				authority: signinAuthority,
				knownAuthorities: [
					signinAuthority,
					pwdResetAuthority,
					profEditAuthority
				],
				clientId: environment.msal.clientId,
				redirectUri: window.location.origin,
				postLogoutRedirectUri: window.location.href,
				navigateToLoginRequestUrl: true
			},
			cache: {
				cacheLocation: BrowserCacheLocation.LocalStorage,
				storeAuthStateInCookie: false,
			},
			system: {
				loggerOptions: {
					logLevel: LogLevel.Verbose,
					piiLoggingEnabled: true,
				},
				allowRedirectInIframe: true,
				allowNativeBroker: false,
			}
		}), {
			interactionType,
			authRequest: () => ({ scopes })
		}, {
			protectedResourceMap: protectedResourceMap(),
			interactionType,
			authRequest: ((msalService: MsalService, req: HttpRequest<unknown>, originalAuthRequest: MsalInterceptorAuthRequest) => ({
				...originalAuthRequest,
				extraQueryParameters: { ui_locales: loginLanguage() }
			}))
		}), GoogleTagManagerModule.forRoot({
			id: environment.googleTagManager.id,
			gtm_auth: environment.googleTagManager.gtmAuth,
			gtm_preview: environment.googleTagManager.gtmPreview,
			gtm_resource_path: environment.googleTagManager.gtmResourcePath
		})),
		WatchlistActionService,
		ViewTypeService,
		WatchlistService,
		TrackEventService,
		{ provide: ErrorHandler, useClass: ErrorHandlerService },
		CssVariableService,
		UserAuthorizationService,
		GlobalVarService,
		CacheService,
		IntervalService,
		ApplicationInsightsService,
		{
			provide: APP_INITIALIZER,
			useFactory: (intervalService: IntervalService) => () => intervalService.init(),
			deps: [IntervalService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (msalService: MsalService) => () => msalService.handleRedirectObservable().subscribe(),
			deps: [MsalService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (viewTypeService: ViewTypeService) => () => viewTypeService.initializeViewTypeByWidthSubscription(),
			deps: [ViewTypeService],
			multi: true
		},

		{
			provide: AccountPublicApi,
			useFactory: (http: HttpClient) => new AccountPublicApi(http, environment.accountApi),
			deps: [HttpClient]
		},
		{
			provide: MsalB2CService,
			useFactory: () => new MsalB2CService(environment)
		},
		{
			provide: AnalyticsTrackingApi,
			useFactory: (http: HttpClient) => new AnalyticsTrackingApi(http, environment.analyticsApi),
			deps: [HttpClient]
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (softwareIntegrationCallbackService: SoftwareIntegrationCallbackService) => async () => { await softwareIntegrationCallbackService.startSession(); },
			deps: [SoftwareIntegrationCallbackService],
			multi: true
		},
		// AnalyticsTrackingService has to be initialized after SoftwareIntegrationCallbackService, to make shure that softwareIntegrationSession already exists, if applicable
		{
			provide: APP_INITIALIZER,
			useFactory: (cacheService: CacheService) => async () => { await cacheService.checkForUpdates(); },
			deps: [CacheService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (translationService: TranslationService) => async () => { await translationService.initTranslation(); },
			deps: [TranslationService],
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (userAutorisationService: UserAuthorizationService) => () => userAutorisationService.subscribeState(),
			deps: [UserAuthorizationService],
			multi: true
		},
		{
			provide: CatalogPublicAPI,
			useFactory: (http: HttpClient) => new CatalogPublicAPI(http, environment.catalogApi),
			deps: [HttpClient]
		},
		interceptorProvider,
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
	]
})
	.catch(err => console.log(err));
