<div ngbNav #nav="ngbNav" #navElement [activeId]="active" [attr.role]="'tablist'" class="nav nav-tabs">
	<a *ngFor="let tab of tabsToShow; let  idx = index" #tabElement [ngbNavItem]="tab" class="d-flex text-dark"
		[routerLink]="getTabRouterLink(tab)" [queryParams]="getQueryParams(tab, idx)" queryParamsHandling="merge" ngbNavLink>
		<!-- {{setPanel(tabElement)}} -->
		<div *ngIf="tab === ViewGroups.Protocol" loading="lazy"
			class="flag-image mask-settings grid-mask-img bg bg-primary flag-filled-mask-img"></div>
		<div>
			{{getTabKey(tab) | translate}}
			<img src="/assets/sustainability/dgnb/nachhaltigkeit.svg" class="sustainability-image" loading="eager" alt="{{getTabKey(tab) | translate}}" *ngIf="tab === ViewGroups.Sustainability"
				>
		</div>
	</a>
</div>