import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackCatalogExternalParams } from '@app/models/tracking/track-event-param-type';
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { CatalogExternalViewType } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { CatalogViewGroupDto, ContactFormType, SocialMediaType } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { AnalyticsTrackingFacadeService } from '@services/analytics-tracking-facade.service';
import { TranslationService } from '@services/translation.service';
import { ButtonComponent } from '../../../components/button/button.component';

@Component({
	selector: 'app-catalog-contact',
	templateUrl: './catalog-contact.component.html',
	styleUrls: ['./catalog-contact.component.scss'],
	standalone: true,
	imports: [NgIf, ButtonComponent, NgFor, TranslateModule]
})
export class CatalogContactComponent implements OnInit {

	@Input() catalogInfo: CatalogViewGroupDto;
	@Input() catalogKey: string;

	public socialMediaType = SocialMediaType;
	public get catalogExternalViewType(): typeof CatalogExternalViewType {
		return CatalogExternalViewType;
	}

	@Output() contactFormOpen = new EventEmitter<any>();

	constructor(
		private translationService: TranslationService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
	) { }

	ngOnInit(): void { }

	public trackWebEmailEvent(type: CatalogExternalViewType, targetUrl: string) {
		this.trackingFacadeService.handleTrackEvent(new TrackCatalogExternalParams(this.catalogKey, type, targetUrl))
	}

	public trackSocialMediaEvent(type: SocialMediaType, targetUrl: string) {
		this.trackingFacadeService.handleTrackEvent(new TrackCatalogExternalParams(this.catalogKey, this.convertSocialMediaEvent(type), targetUrl))
	}

	private convertSocialMediaEvent(type: SocialMediaType): CatalogExternalViewType {
		switch (type) {
			case SocialMediaType.Xing: return CatalogExternalViewType.Xing; break;
			case SocialMediaType.Facebook: return CatalogExternalViewType.Facebook; break;
			case SocialMediaType.Twitter: return CatalogExternalViewType.Twitter; break;
			case SocialMediaType.Instagram: return CatalogExternalViewType.Instagram; break;
			case SocialMediaType.LinkedIn: return CatalogExternalViewType.LinkedIn; break;
			case SocialMediaType.YouTube: return CatalogExternalViewType.YouTube; break;
			case SocialMediaType.Pinterest: return CatalogExternalViewType.Pinterest; break;
			default: return CatalogExternalViewType.Unknown;
		}
	}

	public getSocialMediaLogo(type: SocialMediaType): string {
		const prefix = 'assets\\orca\\socialMedia\\';
		switch (type) {
			case SocialMediaType.Xing: return prefix + 'Xing_icon.svg';
			case SocialMediaType.Facebook: return prefix + 'facebook_icon_rgb.svg';
			case SocialMediaType.Twitter: return prefix + 'Twitter_Social_Icon_Circle_Color.svg';
			case SocialMediaType.Instagram: return prefix + 'instagram_icon.svg';
			case SocialMediaType.LinkedIn: return prefix + 'linkedin_icon.svg';
			case SocialMediaType.YouTube: return prefix + 'youtube_icon_rgb.svg';
			case SocialMediaType.Pinterest: return prefix + 'pinterest__icon.svg';
			default: return '';
		}
	}

	public getLink(link: string) {
		if (link?.startsWith('http://') || link?.startsWith('https://')) {
			return link
		}
		return 'https://' + link;
	}

	openContactForm() {
		this.contactFormOpen.emit({ contactFormType: ContactFormType.CatalogContact, undefined });
	}

	public get dateFormat() {
		return this.translationService.getByKey("global.date-format-short");
	}

}
