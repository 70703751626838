import { Pipe, PipeTransform } from "@angular/core";
import { AccountLanguage } from "@interfaces/HttpClient/AccountApiPublicModels";
import { TranslationService } from "@services/translation.service";
@Pipe({
    name: "languageTranslation",
    pure: false,
    standalone: true
})
export class LanguageTranslationPipe implements PipeTransform {

	private static languagesLoaded: AccountLanguage[] = [];
	constructor(private translationService: TranslationService) { }
	transform(key: string, language: AccountLanguage): string {
		if (language === undefined || language === null) {
			language = AccountLanguage.German;
		}
		if (!LanguageTranslationPipe.languagesLoaded.some(availableLanguage => availableLanguage === language)) {
			LanguageTranslationPipe.languagesLoaded.push(language);
			// eslint-disable-next-line @typescript-eslint/no-floating-promises
			this.translationService.loadLanguage(language);
		}
		return this.translationService.getByKeyAndLanguage(key, language);
	}
}