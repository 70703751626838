import { Injectable } from '@angular/core';
import { ConfirmDialogTextKeyModel } from '@interfaces/ConfirmDialogTextKeyModel';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: "root"
})
export class ConfirmDialogService {

	constructor() { }
	public modalAlreadyOpen = false;
	private subject = new Subject<any>();
	public open(textKeyModel: ConfirmDialogTextKeyModel, confirmEventHandler, dismissEventHandler?, backdropClose?: boolean, closeButton?: boolean) {
		this.subject.next(
			{
				textKeyModel,
				confirmEventHandler,
				dismissEventHandler,
				backdropClose,
				closeButton
			}
		);
	}

	public getConfirmDialog(): Observable<any> {
		return this.subject.asObservable();
	}
}
