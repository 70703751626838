import { NgClass, NgIf } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { convertPascalCasetoCamelCase } from "@app/helpers/case-converter";
import { CatalogDetailsBase } from "@app/super/catalog-details-base";
import { CatalogViewServiceBase } from "@app/super/catalog-view-service-base";
import { TabviewerComponent } from "@app/viewers/tabviewer/tabviewer.component";
import { isNullish } from "@commonHelpers/math-utils";
import { CatalogContentType } from "@enums/CatalogContentType";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewType } from "@enums/view-type";
import { CatalogIntegrationCatalogViewerDto, CatalogIntegrationType, CatalogItemDetailsDto, CatalogViewForeignLanguageCatalogsDto, CatalogViewRelatedCatalogsDto, ExportSettingsDto, ViewGroup, ViewGroupItemDto, Viewer, WatchListStructure } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { CatalogViewService } from "@services/catalog-view.service";
import { SoftwareIntegrationCallbackService } from "@services/software-integration-callback.service";
import { TabService } from "@services/tab.service";
import { TranslationService } from "@services/translation.service";
import { ViewTypeService } from "@services/view-type.service";
import { AttachmentThumbnailsComponent } from "../../../components/attachment-thumbnails/attachment-thumbnails.component";
import { ContentViewerComponent } from "../../../components/content-viewer/content-viewer.component";
import { LoadingComponent } from "../../../components/loading/loading.component";
import { TitleViewerComponent } from "../../../viewers/title-viewer/title-viewer.component";
import { ActionButtonsComponent } from "../action-buttons/action-buttons.component";
import { CatalogContentComponent } from "../catalog-content/catalog-content.component";

@Component({
	selector: "app-catalog-details",
	templateUrl: "./catalog-details.component.html",
	styleUrls: ["./catalog-details.component.scss"],
	standalone: true,
	providers: [TabService],
	imports: [NgIf, TabviewerComponent, TitleViewerComponent, NgClass, ActionButtonsComponent, ContentViewerComponent, AttachmentThumbnailsComponent, LoadingComponent, CatalogContentComponent]
})
export class CatalogDetailsComponent extends CatalogDetailsBase implements OnInit, OnChanges {
	CatalogIntegrationType = CatalogIntegrationType;

	@Input() catalogIntegrationType: CatalogIntegrationType;
	@Input() integrationSettings: CatalogIntegrationCatalogViewerDto;
	@Input() exportSettings: ExportSettingsDto;
	@Input() nodeTitle: string;
	@Output() closeMobileBar = new EventEmitter();


	constructor(
		protected tabService: TabService,
		protected injector: Injector,
		private catalogViewService: CatalogViewService,
		protected viewTypeService: ViewTypeService,
		private cdRef: ChangeDetectorRef,
		private translationService: TranslationService,
		private router: Router,
		private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService
	) { super(tabService, injector, viewTypeService); }

	public Viewer = Viewer;
	public isContentLoading = false;
	public catalogIntegrationTypeEnum = CatalogIntegrationType;
	public overviewType = CatalogContentType.OverviewViewer;
	private storageChangeSubscription: any
	ngOnInit() {
		this.init()
		this.storageChangeSubscription = () => this.cdRef.detectChanges()
		window.addEventListener("storage", this.storageChangeSubscription);
	}

	protected destroy() {
		window.removeEventListener("storage", this.storageChangeSubscription);
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes?.queryParams?.currentValue) { await this.onQueryParamChange() }
		if (changes?.catalogKey?.currentValue) { await this.onCatalogKeyChange(changes?.catalogKey?.currentValue) }
		if (changes?.routingParams?.currentValue) { await this.onRoutingParamChange(changes?.routingParams?.currentValue) }
		if (changes?.catalogViewForeignLanguageCatalogsDto?.currentValue) {
			if (isNullish(this.routingParams.watchlistSection)) {
				this.prepareCatalog()
			}
		}
		if (changes?.catalogViewRelatedCatalogsDto?.currentValue) {
			if (isNullish(this.routingParams.watchlistSection)) {
				this.prepareCatalog()
			}
		}
	}

	private async onQueryParamChange() {
		this.setTabs();
		this.setViewer();
	}

	private async onCatalogKeyChange(currentCatalogKey: string) {

		if (isNullish(this.currentRoutingValues)) {
			return;
		}

		// Tracking:
		// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
		// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/844/Catalog
		if (this.catalogDetailsType === CatalogDetailsType.Public) {
			if (this.isCatalogCurrentlySelectedInTree && isNullish(this.currentRoutingValues.userDefined)) {
				this.prepareCatalog();
			} else {
				await this.preparePosition(this.currentRoutingValues.catalogItemGuid);
			}
		}
	}

	private async onRoutingParamChange(currentRoutingValues: ICatalogRoutingParams) {
		let previousCatalogItemGuid;
		if (!isNullish(this.currentRoutingValues)) {

			previousCatalogItemGuid = this.currentRoutingValues
				.catalogItemGuid;
		}
		this.currentRoutingValues = currentRoutingValues;
		if (!this.isCatalogCurrentlySelectedInTree) {
			await this.preparePosition(this.currentRoutingValues.catalogItemGuid);

		} else if (this.isCatalogCurrentlySelectedInTree && !isNullish(this.currentRoutingValues.key)) {
			this.prepareCatalog();
		} else if (this.routingParams.watchlistSection) {
			this.prepareWatchlistRoot();
		}
	}

	private prepareWatchlistRoot() {
		this.mainViewer = null;
		const viewer = this.currentRoutingValues?.watchlistSection === convertPascalCasetoCamelCase(WatchListStructure[WatchListStructure.UserDefined]) ? isNullish(this.currentRoutingValues?.userDefined) ? Viewer.WatchlistUserdefinedRoot : Viewer.WatchlistUserdefinedFolder : Viewer.WatchlistRoot;
		if (viewer !== Viewer.WatchlistUserdefinedFolder) {
			this.catalogViewService.callNextView();
		}
		const viewGroup = viewer === Viewer.WatchlistRoot || viewer === Viewer.WatchlistUserdefinedRoot ? ViewGroup.WatchlistFolder : ViewGroup.WatchlistUserdefinedFolder
		this.viewGroupDtos = [{
			viewGroup,
			hasThumbnails: false
		}]
		this.tabsViewModel = [viewGroup];
		this.currentMainAttachment = {
			title: this.translationService.getByKey(`watchlist.${viewer === Viewer.WatchlistUserdefinedRoot ? 'userDefined.root' : 'header'}`),
			attachmentType: undefined,
			viewer,
			viewGroup,
			catalogItemId: undefined,
			hasMetadata: false,
			isPreview: false,
			isDownloadLoginRequired: false
		};
		this.hasThumbnails = false;
		this.catalogContentViewersViewModel = undefined;
		this.mainViewer = viewer
		this.isContentLoading = false;

	}

	private prepareCatalog() {

		// Katalog soll auch wirklich nur dann vorbereitet werden wenn keine guid vorhanden ist
		if (!this.isCatalogCurrentlySelectedInTree) {
			return;
		}

		// Behandlung des Nachladens von Reiters weitere Kataloge für die Handyansicht.
		// Dabei muss berücksichtigt werden ob die entsprechenden Daten schon vom Backend geladen werden.
		// Bei der Merkliste hingegen sollen keine weiteren Kataloge angezeigt werden.
		this.catalogViewService.callNextView();
		this.isContentLoading = false;
		if (isNullish(this.routingParams.watchlistSection)) {
			if (!this.isCatalogCurrentlySelectedInTree
				|| isNullish(this.catalogViewForeignLanguageCatalogsDto)
				|| isNullish(this.catalogViewRelatedCatalogsDto)) {
				return;
			}
		}

		this.setCatalogTabs(this.catalogViewForeignLanguageCatalogsDto, this.catalogViewRelatedCatalogsDto)
		// map data to viewer view model
		this.setCatalogViewer(this.catalogViewRelatedCatalogsDto, this.catalogViewForeignLanguageCatalogsDto)
	}

	protected async preparePosition(guid: string) {
		// load catalog item data
		await this.loadAttachments(guid);
		if (isNullish(this.catalogItemDetailsDto)) {
			this.currentMainAttachment = null;
			return
		}

		// map data to tabs view model
		this.setPositionTabs(this.catalogItemDetailsDto)
		// map data to viewer view model
		this.setPositionViewer()
	}

	protected async loadAttachments(guid: string) {
		this.isContentLoading = true;
		this.catalogItemDetailsDto = await this.catalogViewService.getAttachmentsByCatalogItemGuid(guid);
		this.softwareIntegrationCallbackService.setCurrentNode(guid, this.catalogItemDetailsDto.catalogItemId);
		if (isNullish(this.routingParams.watchlistSection) && !isNullish(this.catalogItemDetailsDto) && this.catalogKey !== this.catalogItemDetailsDto?.catalogKey) {
			await this.router.navigateByUrl("/notfound", { replaceUrl: true });
		}
		if (this.currentRoutingValues.catalogItemGuid === guid) {
			this.isContentLoading = false;
		}
	}

	protected setTabs() {
		if (this.isCatalogCurrentlySelectedInTree) {
			this.setCatalogTabs(this.catalogViewRelatedCatalogsDto, this.catalogViewForeignLanguageCatalogsDto)
		} else {
			this.setPositionTabs(this.catalogItemDetailsDto);
		}
	}

	private setCatalogTabs(relatedCatalogs: CatalogViewRelatedCatalogsDto[], foreignLanguageCatalogs: CatalogViewForeignLanguageCatalogsDto[]) {
		// map data to tabs view model
		this.tabsViewModel = [ViewGroup.CatalogInfo]

		if (this.currentViewType !== ViewType.Desktop) {
			this.tabsViewModel.push(ViewGroup.CatalogContact)

			if (foreignLanguageCatalogs?.length > 0 || relatedCatalogs?.length > 0) {
				const isInIntegrationMode = !isNullish(this.catalogIntegrationType) && this.catalogIntegrationType !== CatalogIntegrationType.None

				if (!isInIntegrationMode
					|| (isInIntegrationMode && (this.integrationSettings.showForeignLanguageCatalogs || this.integrationSettings.showRelatedCatalogs))) {
					this.tabsViewModel.push(ViewGroup.CatalogRelations)
				}
			}
		}
		if (this.hasContactPersons) {
			this.tabsViewModel.push(ViewGroup.CatalogContactPersons)
		}
	}

	private setPositionTabs(catalogItemDetailsDto: CatalogItemDetailsDto) {
		// map data to tabs view model
		this.viewGroupDtos = catalogItemDetailsDto
			? Array.from(new Set(catalogItemDetailsDto?.viewGroups?.filter(f => f.viewGroup !== ViewGroup.Unknown)))
			: [];
		this.tabsViewModel = this.viewGroupDtos.map(m => m.viewGroup)

	}

	protected setViewer() {
		if (isNullish(this.currentRoutingValues)) {
			return;
		}
		if (!this.isCatalogCurrentlySelectedInTree) {
			this.setPositionViewer()
		} else if (!isNullish(this.currentRoutingValues.key)) {
			this.setCatalogViewer(this.catalogViewRelatedCatalogsDto, this.catalogViewForeignLanguageCatalogsDto)

		} else if (this.routingParams.watchlistSection) {
			this.prepareWatchlistRoot();
		}
	}

	private setCatalogViewer(relatedCatalogs: CatalogViewRelatedCatalogsDto[], foreignLanguageCatalogs: CatalogViewForeignLanguageCatalogsDto[]) {
		this.currentMainAttachment = {
			attachmentType: null,
			catalogItemId: null,
			hasMetadata: false,
			isDownloadLoginRequired: false,
			isPreview: false,
			viewer: Viewer.CatalogInfoViewer,
			viewGroup: ViewGroup.CatalogInfo
		};

		this.catalogContentViewersViewModel = {
			relatedCatalogs,
			foreignLanguageCatalogs
		}
		switch (this.getTabValue()) {
			case ViewGroup.CatalogContact:
				this.mainViewer = Viewer.CatalogContactViewer;
				break;
			case ViewGroup.CatalogRelations:
				this.mainViewer = Viewer.CatalogRelationsViewer;
				break;
			case ViewGroup.CatalogContactPersons:
				this.mainViewer = Viewer.CatalogContactPersonViewer;
				break;
			default:
				this.mainViewer = Viewer.CatalogInfoViewer;
				break;
		}

		// viewgroupItems zurücksetzten um sicherzustellen, dass keine thumbnails angezeigt werden, wenn man von einer position mit
		// thumbnails zur Katalogsicht navigiert.
		this.viewGroupItems = null;
	}

	public get mobileHeader() {
		if (!isNullish(this.catalogTitle)) {
			return this.catalogTitle;
		}
		return this.currentMainAttachment?.title;
	}

	private setPositionViewer() {
		this.currentMainAttachment = undefined

		let attachment: ViewGroupItemDto;
		const attachments: ViewGroupItemDto[] = this.catalogItemDetailsDto?.viewGroups.flatMap(group => group.viewGroupItems) ?? [];
		if (this.currentRoutingValues.attachmentId) {
			const attachmentId = parseInt(this.currentRoutingValues.attachmentId, null);
			attachment = attachments.find(a => a.id === attachmentId);
		} else if (this.getTabValue()) {
			attachment = attachments?.find(a => a.viewGroup === this.getTabValue());
		} else {
			attachment = attachments?.find(a => a !== undefined);
		}
		this.mainViewer = attachment ? attachment?.viewer : null;

		// Attachments an DGNB Viewer übergeben für Verlinkung der Dokumente
		if (this.mainViewer === Viewer.SustainabilityViewer) {
			this.catalogViewService.setAttachments(attachments?.filter(item => item.viewGroup === ViewGroup.Documents));
		}

		this.currentMainAttachment = attachment;
		this.catalogKey = this.currentRoutingValues.key;
		this.catalogItemGuid = this.currentRoutingValues.catalogItemGuid;

		const viewGroup = this.getTabValue() === ViewGroup.Overview ?
			this.catalogItemDetailsDto?.viewGroups.find(group => group.viewGroup === ViewGroup.ImageAndVideo || group.viewGroup === ViewGroup.Images)
			: this.catalogItemDetailsDto?.viewGroups.find(group => group.viewGroup === this.getTabValue());
		this.viewGroupItems = viewGroup?.viewGroupItems;
		this.hasThumbnails = viewGroup?.hasThumbnails ?? false;
	}

	public getCatalogViewService(): CatalogViewServiceBase {
		return this.catalogViewService
	}
}
