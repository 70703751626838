import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { CatalogContentViewersViewModel } from '@app/super/catalog-details-base';
import { CatalogViewServiceBase } from '@app/super/catalog-view-service-base';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogDetailsType } from '@enums/catalog-details-type';
import { CatalogLanguage, FeatureToggleValuesDto, ViewGroupItemDto, Viewer } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogQueryParams, ICatalogRoutingParams } from '@interfaces/iCatalogRoutingParams';
import { IContactFormOpen } from '@interfaces/iContactFormOpen';

@Component({
	selector: 'app-content-viewer',
	templateUrl: './content-viewer.component.html',
	styleUrls: ['./content-viewer.component.scss'],
	standalone: true
})
export class ContentViewerComponent implements OnInit, OnChanges {

	@ViewChild("viewer", { static: true, read: ViewContainerRef }) viewer: ViewContainerRef;
	@Input() mainViewer: Viewer;
	@Input() attachment: ViewGroupItemDto;
	@Input() attachments: ViewGroupItemDto[];
	@Input() catalogKey: string;
	@Input() catalogItemGuid: string;
	@Input() url: string;
	@Input() catalogContentViewersViewModel: CatalogContentViewersViewModel
	@Input() routingParams: ICatalogRoutingParams;
	@Input() queryParams: ICatalogQueryParams;
	@Input() featureToggles: FeatureToggleValuesDto;
	@Input() catalogViewService: CatalogViewServiceBase;
	@Input() catalogDetailsType: CatalogDetailsType;
	@Input() catalogLanguage: CatalogLanguage;

	@Output() contactFormOpen = new EventEmitter<IContactFormOpen>();

	public currentRoutingValues: ICatalogRoutingParams = {};
	public currentQueryParamsValues: ICatalogQueryParams = {};
	private component: any;

	constructor() {
	}

	ngOnInit(): void { }

	async ngOnChanges(changes: SimpleChanges) {
		if (changes?.routingParams?.currentValue) {
			this.currentRoutingValues = changes.routingParams.currentValue;
		}
		if (changes?.queryParams?.currentValue) {
			this.currentQueryParamsValues = changes.queryParams.currentValue;
		}

		const catalogItemGuid = this.currentRoutingValues.catalogItemGuid
		const componentRef = await this.getComponent(this.mainViewer);

		// Filter damit die Viewer nicht mehrfach geladen werden
		if (this.viewer && componentRef
			&& (JSON.stringify(changes?.attachment?.currentValue) !== JSON.stringify(changes?.attachment?.previousValue)			// Unterschiedliches Attachment
				|| changes?.mainViewer?.currentValue === Viewer.CatalogInfoViewer												// Immer bei CatalogInfoViewer
				|| changes?.catalogContentViewersViewModel																			// Wenn der Katalog direkt geladen wird
			)
		) {
			this.viewer.clear();
			this.component = this.viewer.createComponent(componentRef as any);

			const component = this.component.instance as any;
			if (!isNullish(this.component)) {
				component.catalogViewService = this.catalogViewService;
				component.catalogDetailsType = this.catalogDetailsType;
				component.currentRoutingParams = this.routingParams;
				component.queryParams = this.currentQueryParamsValues;
			}
			if (this.attachment) {
				component.attachment = changes?.attachment?.currentValue
			}
			if (this.catalogKey) {
				component.catalogKey = this.catalogKey;
			}
			if (catalogItemGuid) {
				component.catalogItemGuid = catalogItemGuid;
			}
			if (this.url) {
				component.url = this.url;
			}
			if (this.catalogContentViewersViewModel) {
				component.relatedCatalogs = this.catalogContentViewersViewModel.relatedCatalogs
				component.languageCatalogs = this.catalogContentViewersViewModel.foreignLanguageCatalogs
				component.currentRoutingParams = this.routingParams;
			}

			if (this.attachments) {
				component.attachments = this.attachments;
			}
			if (this.contactFormOpen) {
				component.contactFormOpen = this.contactFormOpen;
			}
			if (this.featureToggles) {
				component.featureToggles = this.featureToggles;
			}
			component.catalogLanguage = this.catalogLanguage;
		}
	}


	private async getComponent(viewer: Viewer) {
		switch (viewer) {
			case Viewer.LinkViewer: return (await import('@app/viewers/linkviewer/linkviewer.component')).LinkViewerComponent;
			case Viewer.ImageViewer: return (await import('@app/viewers/imageviewer/imageviewer.component')).ImageViewerComponent;
			case Viewer.PdfViewer: return (await import('@app/viewers/pdfviewer/pdfviewer.component')).PdfviewerComponent;
			case Viewer.HtmlViewer: return (await import('@app/viewers/htmlviewer/htmlviewer.component')).HtmlviewerComponent;
			case Viewer.AutodeskViewer: return (await import('@app/viewers/autodeskviewer/autodeskviewer.component')).AutodeskViewerComponent;
			case Viewer.BimObjectViewer: return (await import('@app/viewers/bimobjectviewer/bimobjectviewer.component')).BimobjectViewerComponent;
			case Viewer.CadenasViewer: return (await import('@app/viewers/cadenasviewer/cadenasviewer.component')).CadenasViewerComponent;
			case Viewer.CadClickViewer: return (await import('@app/viewers/cadclickviewer/cadclickviewer.component')).CadClickViewerComponent;
			case Viewer.FileViewer: return (await import('@app/viewers/fileviewer/fileviewer.component')).FileviewerComponent;
			case Viewer.IfcViewer: return this.featureToggles?.simpleFeatureToggles.CatalogViewAutoDeskForgeForIfc ?
				(await import('@app/viewers/autodeskviewer/autodeskviewer.component')).AutodeskViewerComponent :
				(await import('@app/viewers/ifcviewer/ifcviewer.component')).IfcViewerComponent;
			case Viewer.VimeoVideoViewer: return (await import('@app/viewers/vimeovideoviewer/vimeovideoviewer.component')).VimeoVideoViewerComponent;
			case Viewer.YoutubeVideoViewer: return (await import('@app/viewers/ytvideoviewer/ytvideoviewer.component')).YTVideoViewerComponent; // neuer YouTube Viewer
			case Viewer.FileVideoViewer: return (await import('@app/viewers/filevideoviewer/filevideoviewer.component')).FileVideoViewerComponent;
			case Viewer.TextViewer: return (await import('@app/viewers/textviewer/textviewer.component')).TextviewerComponent;
			case Viewer.ProtocolViewer: return (await import('@app/viewers/protocolviewer/protocolviewer.component')).ProtocolviewerComponent;
			case Viewer.FolderContentViewer: return (await import('@app/viewers/folder-viewer/folder-viewer.component')).FolderViewerComponent;
			case Viewer.CatalogInfoViewer: return (await import('@app/viewers/cataloginfoviewer/cataloginfoviewer.component')).CatalogInfoViewerComponent;
			case Viewer.CatalogContactViewer: return (await import('@app/viewers/catalogcontactviewer/catalogcontactviewer.component')).CatalogContactViewerComponent;
			case Viewer.CatalogRelationsViewer: return (await import('@app/viewers/catalogrelationsviewer/catalogrelationsviewer.component')).CatalogRelationsViewerComponent;
			case Viewer.CatalogContactPersonViewer: return (await import('@app/viewers/catalogcontactperson-viewer/catalogcontactperson-viewer.component')).CatalogcontactpersonViewerComponent;
			case Viewer.WatchlistRoot: return (await import('@app/viewers/watchlist-rootviewer/watchlist-rootviewer.component')).WatchlistRootviewerComponent;
			case Viewer.WatchlistUserdefinedRoot: return (await import('@app/viewers/watchlist-userdefined-rootviewer/watchlist-userdefined-rootviewer.component')).WatchlistUserdefinedRootviewerComponent;
			case Viewer.WatchlistUserdefinedFolder: return (await import('@app/viewers/folder-viewer/folder-viewer.component')).FolderViewerComponent;
			case Viewer.SustainabilityViewer: return (await import('@app/viewers/sustainability-viewer/sustainability-viewer.component')).SustainabilityViewerComponent;
		}
	}

}
