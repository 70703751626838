<div class="h-100 d-flex flex-column">
	<div class="flex-grow-1">
		<app-mobile-header [catalogKey]="catalogKey"></app-mobile-header>
	</div>
	<main class="h-100 overflow-hidden">
		<ng-container #viewer></ng-container>
	</main>
	<div class="flex-grow-1">
		<app-mobile-bar #mobileBar [disabledMobileBarButtons]="disabledMobileBarButtons" [initMobileBarButton]="initMobileBarButton"
			[mobileBarButtons]="mobileBarButtons" (buttonChanged)="setCurrentView($event)"></app-mobile-bar>
	</div>
</div>