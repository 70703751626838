// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 

import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogDetailsType } from '@enums/catalog-details-type';
import { CatalogViewCatalogInformationDto, CatalogViewForeignLanguageCatalogsDto, CatalogViewRelatedCatalogsDto } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogQueryParams, ICatalogRoutingParams } from '@interfaces/iCatalogRoutingParams';
import { IContactFormOpen } from '@interfaces/iContactFormOpen';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CatalogTreeService } from '@services/catalog-tree.service';
import { CatalogViewService } from '@services/catalog-view.service';
import { TranslationService } from '@services/translation.service';
import { CatalogDetailsComponent } from "../catalog-details/catalog-details.component";

@Component({
	selector: 'app-catalog-mobile-info',
	standalone: true,
	providers: [CatalogTreeService, CatalogViewService],
	templateUrl: './catalog-mobile-info.component.html',
	styleUrls: ['./catalog-mobile-info.component.scss'],
	imports: [CommonModule, CatalogDetailsComponent]
})
export class CatalogMobileInfoComponent implements OnInit, OnChanges {
	@Input() catalog: CatalogViewCatalogInformationDto;
	@Input() catalogKey: string;

	public catalogRoutingParams: ICatalogRoutingParams;
	public catalogQueryParams: ICatalogQueryParams;
	public catalogDetailsType = CatalogDetailsType.Public;
	public languageCatalogs: CatalogViewForeignLanguageCatalogsDto[];
	public relatedCatalogs: CatalogViewRelatedCatalogsDto[];

	constructor(private route: ActivatedRoute,
		private modalService: NgbModal,
		private translationService: TranslationService,
		private catalogViewService: CatalogViewService) {

	}


	ngOnInit(): void {
		this.route.params.subscribe(async params => {
			this.catalogRoutingParams = {
				key: params.key,
				view: this.route.snapshot.params.view,
				optionalCatalogItemGuidForTab: params.catalogItemGuid
			};


		});
		this.route.queryParams.subscribe(async params => {
			this.catalogQueryParams = params;
		});
	}

	async ngOnChanges(changes: SimpleChanges) {
		if (!isNullish(changes.catalog?.currentValue)) {
			const furtherCatalogs = await Promise.all([this.catalogViewService.getForeignLanguageCatalogs(this.catalog.key), this.catalogViewService.getRelatedCatalogs(this.catalog.key)]);
			this.languageCatalogs = furtherCatalogs?.[0];
			this.relatedCatalogs = furtherCatalogs?.[1];
		}
	}




	async openContactForm(item: IContactFormOpen) {
		const catalogInfoOrPosition = this.translationService.getByKey('catalog-view.roottitle');
		const options: NgbModalOptions = {
			backdrop: 'static',
			modalDialogClass: 'contact-form'
		};
		const contactForm = (await import('@app/components/contact-form/contact-form.component')).ContactFormComponent
		const modalRef = this.modalService.open(contactForm, options);
		modalRef.componentInstance.catalog = this.catalog;
		modalRef.componentInstance.catalogInfoOrPosition = catalogInfoOrPosition;
		if (!isNullish(item.personId)) {
			modalRef.componentInstance.contactPersonId = item.personId;
		}
		modalRef.componentInstance.type = item.contactFormType;
	}

}
