import { NgClass, NgForOf, NgIf } from "@angular/common";
import {
	Component,
	ElementRef,
	Input, OnChanges, ViewChild
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { isNullish } from "@commonHelpers/math-utils";
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewGroup, ViewGroupDto } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogQueryParams, ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { NgbNavModule } from "@ng-bootstrap/ng-bootstrap";
import { TabService } from "@services/tab.service";

@Component({
	selector: "app-tabviewer",
	templateUrl: "./tabviewer.component.html",
	styleUrls: ["./tabviewer.component.scss"],
	standalone: true,
	imports: [NgIf, NgForOf, NgClass, TranslateModule, NgbNavModule, RouterModule],
})
export class TabviewerComponent implements OnChanges {

	@Input() viewGroupDtos: ViewGroupDto[]
	@Input() routingParams: ICatalogRoutingParams;
	@Input() queryParams: ICatalogQueryParams;
	@Input() tabs: ViewGroup[];
	@Input() catalogDetailsType: CatalogDetailsType
	@ViewChild("navElement") nav: ElementRef;

	public ViewGroups = ViewGroup;
	public navId = '';
	public tabsToShow: ViewGroup[];

	// Tabs / ViewGroups, die zwar vom ViewGroupService ausgeliefert werden, aber im Frontend nicht angezeigt werden.
	private hiddenTabs: ViewGroup[] = [
		ViewGroup.Error
	];

	public get active() {
		const tabChildren = Array.from(this.nav?.nativeElement.children ?? [])
		const element: any = tabChildren.find((e: HTMLElement) => e.attributes['aria-selected']?.value === "true");
		this.navId = element?.getAttribute('aria-controls') ?? '';
		return this.tabService.getTabValue(this.queryParams?.tab, this.tabsToShow);
	}

	constructor(private tabService: TabService) { }

	ngOnChanges() {
		this.tabsToShow = this.tabs.filter(t => !this.hiddenTabs.includes(t));
	}

	public getTabKey(tab: ViewGroup) {
		return "attachments." + ViewGroup[tab];
	}

	public getQueryParams(tab: ViewGroup, idx: number) {
		return { tab: idx === 0 ? null : ViewGroup[tab] }
	}

	public getTabRouterLink(tab: ViewGroup) {
		// Unterscheide Public und Preview
		let linkparams;
		if (this.catalogDetailsType === CatalogDetailsType.WatchlistDefault || this.catalogDetailsType === CatalogDetailsType.WatchlistUserdefined) {
			linkparams = ["/watchlist", this.routingParams.watchlistSection];
			if (!isNullish(this.routingParams.userDefined)) {
				linkparams.push(this.routingParams.userDefined)
			}
			if (!isNullish(this.routingParams?.key) && isNullish(this.routingParams?.catalogItemGuid)) {
				linkparams = linkparams.concat([
					"catalog",
					this.routingParams?.key
				]);
			}
		} else if (this.catalogDetailsType === CatalogDetailsType.Public) {
			linkparams = ["/catalog", this.routingParams?.key];
		} else if (this.catalogDetailsType === CatalogDetailsType.Preview) {
			linkparams = ["/preview", this.routingParams?.key];
		}

		if (this.routingParams?.catalogItemGuid) {
			linkparams = linkparams.concat([
				"catalogItem",
				this.routingParams?.catalogItemGuid
			]);
		}

		// In MobileAnsicht soll bei CatalogAnsicht die Guid bei Klick auf einen Tab nicht vergessen werden
		if (this.routingParams?.optionalCatalogItemGuidForTab) {
			linkparams = linkparams.concat([
				"catalogItem",
				this.routingParams?.optionalCatalogItemGuidForTab
			]);
		}

		const currentViewGroup = this.viewGroupDtos?.find(viewGroupDto => viewGroupDto.viewGroup === tab)
		const hasFirstAttachmentId = currentViewGroup?.viewGroupItems?.[0]?.id

		if (hasFirstAttachmentId) {
			linkparams = linkparams.concat([
				"attachment",
				currentViewGroup?.viewGroupItems?.[0]?.id
			]);
		}
		if (this.routingParams?.view) {
			linkparams = linkparams.concat([
				this.routingParams.view
			]);
		}
		return linkparams;
	}
}
