import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
	providedIn: 'root'
})
export class ClipboardService {

	constructor(private deviceDetectorService: DeviceDetectorService) { }

	public async isCopyToClipboardIsUsable() {

		if (this.deviceDetectorService.browser === "Firefox" || this.deviceDetectorService.browser === "Safari") {
			// Die Permissions funktionieren nicht im Firefox und Safari aber man kann es in einen IFrame auch nicht einschränken
			return true
		} else {
			try {
				const clipboardRead = (await (window.navigator.permissions as any)?.query({ name: "clipboard-read" })).state !== 'denied';
				const clipboardWrite = (await (window.navigator.permissions as any)?.query({ name: "clipboard-write" })).state !== 'denied';
				return clipboardRead && clipboardWrite;
			} catch (e) {
				return true;
			}

		}

	}

}
