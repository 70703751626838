import { Injectable } from '@angular/core';
import { Client as CatalogPublicAPI } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { firstValueFrom } from 'rxjs';
import { RequestService } from './request.service';

@Injectable({
	providedIn: 'root'
})
export class PreviewAuthorizationService {

	constructor(private catalogPublicAPI: CatalogPublicAPI, private requestService: RequestService) { }

	async getCatalogIdByCatalogKeyForPreview(
		catalogKey: string
	): Promise<number> {
		try {
			return await firstValueFrom(this.catalogPublicAPI.common_GetCatalogIdByCatalogKeyForPreview(catalogKey));
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}
}
