// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 

import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TrackCatalogItemParams, TrackCatalogParams } from "@app/models/tracking/track-event-param-type";
import { NavigateCatalogParams, NavigateSearchbarParams } from "@app/models/tracking/track-history-param-type";
import { ViewType } from "@enums/view-type";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { TranslationService } from "@services/translation.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription } from "rxjs";

@Component({
	selector: "app-catalog",
	templateUrl: "./catalog.component.html",
	styleUrls: ["./catalog.component.scss"],
	standalone: true,
	imports: [
		CommonModule,
	]
})

export class CatalogComponent implements OnInit, OnDestroy {

	@ViewChild("viewer", { static: true, read: ViewContainerRef }) viewer: ViewContainerRef;
	private currentViewType: ViewType;
	private routeSubscription: Subscription;
	private viewTypeSubscription: Subscription;

	constructor(
		private viewTypeService: ViewTypeService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private route: ActivatedRoute,
		private translationService: TranslationService
	) {
	}


	ngOnInit() {
		// Tracking bei Katalogen
		if (this.route.snapshot.params.view === "search") {
			this.trackingFacadeService.handleTrackHistory(new NavigateSearchbarParams(false))
		}
		this.trackingFacadeService.handleTrackHistory(new NavigateCatalogParams(this.route.snapshot.params.view === "search"));
		let trackCatalogKey: string;
		let trackCatalogGuid: string;
		this.routeSubscription = this.route.params.subscribe(params => {
			if (trackCatalogKey !== params.key) {
				this.trackingFacadeService.handleTrackEvent(new TrackCatalogParams(params.key));
				trackCatalogKey = params.key;
			}
			if (trackCatalogGuid !== params.catalogItemGuid) {
				this.trackingFacadeService.handleTrackEvent(new TrackCatalogItemParams(params.key, params.catalogItemGuid));
				trackCatalogGuid = params.catalogItemGuid;
			}

			if (params.view !== "search") {
				this.trackingFacadeService.endSearch();
			}
		})
		this.viewTypeSubscription = this.viewTypeService.currentViewType$.subscribe(async viewType => {
			if (this.currentViewType !== viewType) {
				this.currentViewType = viewType;
				this.viewer.clear();
				if (viewType === ViewType.Mobile) {
					const component = (await import('./catalog-mobile/catalog-mobile.component')).CatalogMobileComponent;
					this.viewer.createComponent(component);
				}

				if (viewType === ViewType.Desktop) {
					const component = (await import('./catalog-desktop/catalog-desktop.component')).CatalogDesktopComponent;
					this.viewer.createComponent(component);
				}
			}
		});
	}

	async ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
		await this.translationService.setDefaultLanguage();
		this.viewTypeSubscription?.unsubscribe();
	}
}
