import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { environment } from '@environments/environment';
import { SoftwareIntegrationCallbackService } from '@services/software-integration-callback.service';

@Component({
	selector: 'app-infoletter-hover',
	templateUrl: './infoletter-hover.component.html',
	styleUrls: ['./infoletter-hover.component.scss', '../global-hover-item.scss'],
	standalone: true,
	imports: [NgIf, TranslateModule]
})
export class InfoletterHoverComponent implements OnInit {

	constructor(private softwareIntegrationCallbackService: SoftwareIntegrationCallbackService) { }
	@Input() expandInfoletter = true;
	public static infoletterClicked = false;

	ngOnInit(): void {
	}

	public get initShow() {
		return !InfoletterHoverComponent.infoletterClicked && this.expandInfoletter;
	}

	public get showContent() {
		return !(this.softwareIntegrationCallbackService.hasSession() || isInIframe())
	}

	public get infoletterLink() {
		return environment.infoletterUrl;
	}

	public infoLetterClicked() {
		InfoletterHoverComponent.infoletterClicked = true;
	}

}
