import { Injectable } from "@angular/core";
import { HTTPStatusCode } from "@enums/statusCodes";

@Injectable({
	providedIn: "root"
})
export class RequestService {
	constructor() { }

	public errorHandling(
		e,
		defaultErrorReturn,
		specificCodes?: { status: HTTPStatusCode; return: any }[]
	) {
		if (specificCodes && specificCodes.some(s => s.status === e.status)) {
			const returnValue = specificCodes.find(f => f.status === e.status).return
			return returnValue instanceof Function ? returnValue() : returnValue
		} else {
			return defaultErrorReturn;
		}
	}
}
