<div *ngIf="!isLoggedIn; else loggedInBtn" ngbDropdown #userMenuDropdownNotLoggendIn="ngbDropdown" id="login-button"
	placement="bottom-right" container="body" [dropdownClass]="'usermenu-dropdown-placement'" (mouseenter)="onHoverDropdown()"
	(mouseleave)="leaveDropdownButton($event)" (click)="onHoverDropdown()">
	<app-user-menu-button-img *ngIf="loaded" [hover]="hover" (click)="userMenuChanged(); login()" class="toggle-dropdown"
		data-toggle="dropdown" ngbDropdownToggle id="usermenu-dropdown" class="cursor-pointer"
		[inputTranslate]="'user-menu.login' |translate">
	</app-user-menu-button-img>
	<app-loading *ngIf="!loaded" [color]="'gray-700'"></app-loading>
	<div id="dropdownNotLoggedIn" ngbDropdownMenu aria-labelledby="usermenu-dropdown" (mouseenter)="hoverCheck()"
		(mouseleave)="onLeaveDropdown()">
		<div class="w-100 px-4 pt-4 pb-3 d-flex flex-column align-items-center bg-gray-200">
			<div class="h5 text-center">{{ "user-menu.not-logged-in.title" |translate }}</div>
			<div class="d-flex flex-wrap justify-content-center">
				<span>{{ "user-menu.not-logged-in.text1" |translate }}</span>
				<div class="app-link-dark px-1" (click)="openLoginProfits()">{{ "user-menu.not-logged-in.text-link" |translate }}</div>
				<span class="text-center pb-3 ">{{ "user-menu.not-logged-in.text2" |translate }}</span>
			</div>
			<div class="d-flex justify-content-center flex-wrap">
				<div class="px-2 pb-2">
					<app-button variant="primary" [width]="9" (clicked)="login()">{{ "user-menu.login" |translate }}</app-button>
				</div>
				<div *ngIf="hasSignup()" class="px-2 pb-2">
					<app-button variant="primary" [width]="9" (clicked)="signup()">{{ "user-menu.signup" |translate }}</app-button>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #loggedInBtn>
	<div ngbDropdown #userMenuDropdownLoggendIn="ngbDropdown" placement="bottom-right" container="body"
		[dropdownClass]="'usermenu-dropdown-placement'">
		<button type="button" aria-label="Open user menu" class="btn toggle-dropdown initial-button my-2 p-0" data-display="static"
			data-toggle="dropdown" ngbDropdownToggle id="dropdown2" (click)="userMenuChanged()">
			<div class="initials">{{ initials }}</div>
		</button>
		<div class="dropdown-menu overflow-auto max-height-menu" ngbDropdownMenu aria-labelledby="dropdown2">
			<div *ngIf="userMenuDropdownLoggendIn.isOpen()">
				<app-user-menu [catalogId]="catalogId" [catalogKey]="catalogKey" [companyId]="companyId" [target]="target" [rel]="rel"
					(logout)="logout()" (login)="login()" (closeMenu)="userMenuDropdownLoggendIn.close()"></app-user-menu>
			</div>

		</div>
	</div>
</ng-template>