import { NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewType } from "@enums/view-type";
import { ViewGroupItemDto, Viewer } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";

@Component({
	selector: "app-attachment-thumbnails",
	templateUrl: "./attachment-thumbnails.component.html",
	styleUrls: ["./attachment-thumbnails.component.scss"],
	standalone: true,
	imports: [NgIf, NgFor, RouterLink, NgClass]
})
export class AttachmentThumbnailsComponent implements OnInit {
	@Input() routingParams: ICatalogRoutingParams;
	@Input() viewGroupItems: ViewGroupItemDto[];
	@Input() isEmbeded = false;
	@Input() currentViewType: ViewType
	@Input() catalogDetailsType: CatalogDetailsType
	public viewType = ViewType

	public Viewer = Viewer;

	constructor() { }

	ngOnInit() {
	}

	public get selectedItem(): ViewGroupItemDto {
		if (this.routingParams?.attachmentId) {
			return this.viewGroupItems.find(item => item.id === +this.routingParams?.attachmentId);
		}

		return this.viewGroupItems.find(item => item !== undefined);
	}


	getRouterLink(item: ViewGroupItemDto) {
		const viewSegment = this.routingParams.view ? "/" + this.routingParams.view : ""
		return `${this.getModeSegment()}/catalogItem/${this.routingParams.catalogItemGuid}/attachment/${item.id}${viewSegment}`
	}

	private getModeSegment() {
		switch (this.catalogDetailsType) {
			case CatalogDetailsType.WatchlistDefault: return `/watchlist/${this.routingParams.watchlistSection}`;
			case CatalogDetailsType.Public: return `/catalog/${this.routingParams.key}`;
			case CatalogDetailsType.Preview: return `/preview/${this.routingParams.key}`;
			case CatalogDetailsType.WatchlistUserdefined: return `/watchlist/${this.routingParams.watchlistSection}/${this.routingParams.userDefined}`
		}
	}
}
