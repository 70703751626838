import { EventEmitter, Injectable, Output } from "@angular/core";
import { GlobalTranslationVar } from "@app/class/GlobalTranslationVar";
import { isNullish } from "@commonHelpers/math-utils";
import { TranslateService } from "@commonNodeModules/@ngx-translate/core";
import { Language } from "@enums/language";
import { AccountLanguage } from "@interfaces/HttpClient/AccountApiPublicModels";
import { CatalogLanguage } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { GlobalVarService } from "./global-var.service";

declare const CCM;
@Injectable({
	providedIn: "root"
})
export class TranslationService {
	private languages = new Map<string, any>()

	constructor(private translateService: TranslateService, private globalVarService: GlobalVarService
	) { }

	/*     formatMessage = formatMessage; */

	@Output() languageChanged = new EventEmitter();

	private async switchLanguage(language: Language) {
		const translations = await this.translateService.use(language).toPromise();
		if (!this.languages.has(language)) {
			this.languages.set(language, translations);
		}
		GlobalTranslationVar.SELECTED_LANGUAGE = language;
		this.languageChanged.emit(language);
		this.setCCM19Language(language);

	}

	public setCCM19Language(language: Language) {
		try {
			if (!isNullish(CCM)) {
				this.switchCcm19Language(language);
			} else {
				const switchLanguageFunction = () => {
					this.switchCcm19Language(language);
					window.removeEventListener('ccm19WidgetLoaded', switchLanguageFunction);
				}
				window.addEventListener('ccm19WidgetLoaded', switchLanguageFunction);
			}
		} catch {

		}
	}

	private switchCcm19Language(language: Language) {
		if (language === Language.DE) {
			CCM?.switchLocale('de_DE')
		} else {
			CCM?.switchLocale('en_US')
		}
	}

	async switchCatalogLanguage(catalogLanguage: CatalogLanguage) {
		if (catalogLanguage === CatalogLanguage.German) {
			await this.switchLanguage(Language.DE);
		} else {
			await this.switchLanguage(Language.EN)
		}

	}

	async switchAdminLanguage(accountLanguage: AccountLanguage) {
		switch (accountLanguage) {
			case AccountLanguage.English: { await this.switchLanguage(Language.EN); break }
			case AccountLanguage.German: { await this.switchLanguage(Language.DE); break }
		}
	}

	private getLanguageKey(accountLanguage: AccountLanguage) {
		switch (accountLanguage) {
			case AccountLanguage.English: return Language.EN;
			case AccountLanguage.German: return Language.DE;
		}
	}

	getLanguage() {
		let lang = GlobalTranslationVar.SELECTED_LANGUAGE as string;

		if (!lang) {
			lang = Language.DE;
		}

		return lang.substring(0, 2).toLowerCase();
	}

	getByKey(key: string, params?) {
		if (params) {
			return this.translateService.instant(key, params);
		}
		return this.translateService.instant(key);
	}

	getByKeyAndLanguage(key: string, accountLanguage: AccountLanguage): string {
		if (accountLanguage === undefined || accountLanguage === null) {
			return "";
		}
		const translationKey = this.getLanguageKey(accountLanguage);
		if (this.languages.has(translationKey)) {
			return this.translateService.getParsedResult(this.languages.get(translationKey), key);
		}
		return undefined;

	}

	async loadLanguage(accountLanguage: AccountLanguage) {
		if (isNullish(accountLanguage)) {
			return "";
		}
		const translationKey = this.getLanguageKey(accountLanguage);
		if (!this.languages.has(translationKey)) {
			const translations = await this.translateService.reloadLang(translationKey).toPromise();
			this.languages.set(translationKey, translations);
		}
		return true;
	}

	async setDefaultLanguage() {
		await this.switchLanguage(Language.DE);
	}

	async initTranslation() {
		// bei user soll auch beim initalen aufruf die Sprache gesetzt werden. Da keine Id oder Unterteilung vorhanden sein kann muss in dem Fall der Slash weggelassen werden  
		const translationUrls = ["/administration/companies/",
			"/administration/catalog/",
			"/administration/user",
			"/watchlist/",
			"/preview/"]
		if (!isNullish(this.globalVarService.getLanguage()) && (translationUrls.some(e => window.location.pathname.startsWith(e)))) {
			await this.switchAdminLanguage(this.globalVarService.getLanguage());
		} else {
			await this.setDefaultLanguage();
		}
	}
}
