<div class="w-100 pt-3 px-4 pb-2">
	<div *ngIf="isLoggedIn" class="d-flex credentials flex-column align-items-center">
		<div>
			<div *ngIf="model" class="initials bg-primary">
				{{ initials }}
			</div>
			<div *ngIf="!model">
				<app-loading></app-loading>
			</div>
		</div>
		<div class="text-dark d-flex flex-fill flex-column align-items-center">
			<div class="fw-bold mt-2 px-1">{{ name }}</div>
			<div class="my-1 px-1">{{ email }}</div>
			<app-user-menu-responsive-link [link]="['/administration','user']" styleClass="my-2" [target]="target" [rel]="rel"
				direction="user" (close)="closeMenu.emit()">
				<app-button link mobileTablet width="10">
					<div class="px-1">{{ "user-menu.user-account" |
						languageTranslation: accountLanguage }}</div>
				</app-button>
			</app-user-menu-responsive-link>
		</div>
	</div>
	<div *ngIf="model?.isAdmin">
		<div class="d-flex fw-bold align-items-center border-top pt-2 mt-2">
			<div class="me-3">
				<img src="/assets/orca/userMenu/orca_wal.svg" loading="lazy" alt="Orca Wal" style="height: 2rem; width: 2rem; ">
			</div>
			<div class="list-group-item-action px-1 cursor-pointer">
				<app-user-menu-responsive-link [link]="['/administration','orca-admin']"
					styleClass="text-decoration-none text-dark line-height-large one-line w-100 " [target]="target" [rel]="rel"
					direction="orca-admin" (close)="closeMenu.emit()">
					<div>{{ "user-menu.orca-admin" |
						languageTranslation:
						accountLanguage }}</div>
				</app-user-menu-responsive-link>
			</div>

		</div>
	</div>
	<div *ngIf="model?.companyAdministrations.length > 0">
		<div class="d-flex fw-bold align-items-center border-top pt-2 mt-2 px-1">
			<div class="me-3">
				<img src="/assets/orca/userMenu/business.svg" loading="lazy" alt="Lizenznehmer" style="height: 2rem; width: 2rem; ">
			</div>
			<div class="me-3">{{ "user-menu.company" | languageTranslation: accountLanguage }}</div>
		</div>
		<div class="mt-2 img-width-placeholder">
			<ul class="ps-0 ms-3">
				<li *ngFor="let company of model?.companyAdministrations" class="list-group-item-action px-1 cursor-pointer">
					<app-user-menu-responsive-link [link]="['/administration','companies', company.companyOrCatalogId]"
						styleClass="text-dark text-decoration-none might-overflow one-line " direction="company" [target]="target"
						[rel]="rel" (close)="closeMenu.emit()">
						<div [ngbTooltip]="company.title" tooltipClass="tooltip-user-menu" placement="left" container="body"
							[openDelay]="800">
							{{ company.title }}
						</div>
					</app-user-menu-responsive-link>
				</li>
			</ul>
		</div>
	</div>
	<div *ngIf="model?.catalogAdministrations?.length > 0">
		<div class="d-flex fw-bold align-items-center border-top pt-2 mt-2 px-1">
			<div class="me-3">
				<img src="/assets/orca/userMenu/menu_book.svg" loading="lazy" alt="Katalog" style="height: 2rem; width: 2rem; ">
			</div>
			<div class="me-3">{{ "user-menu.catalog" | languageTranslation: accountLanguage }}</div>
		</div>
		<div class="mt-2 img-width-placeholder">
			<ul class="ps-0 ms-3">
				<li *ngFor="let catalog of model?.catalogAdministrations" [class.active]="isCatalogActive(catalog)"
					class="list-group-item-action px-1 cursor-pointer">
					<app-user-menu-responsive-link [link]="['/administration','catalog', catalog.companyOrCatalogId]" direction="catalog"
						styleClass="text-dark text-decoration-none might-overflow one-line " [target]="target" [rel]="rel"
						(close)="closeMenu.emit()">
						<div [ngbTooltip]="catalog.title" tooltipClass="tooltip-user-menu" placement="left" container="body"
							[openDelay]="800">
							{{ catalog.title }}
						</div>
					</app-user-menu-responsive-link>
				</li>
			</ul>
		</div>
	</div>
</div>
<div class="border-top pt-3 pb-3 text-center">
	<app-button *ngIf="isLoggedIn" width="10" (clicked)="logout.emit()">
		{{ "user-menu.logout" | languageTranslation: accountLanguage }}
	</app-button>
	<app-button *ngIf="!isLoggedIn" width="10" (clicked)="login.emit()">
		{{ "user-menu.login" | translate }}
	</app-button>
</div>