/* eslint-disable max-classes-per-file */
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatchResult, UrlSegment } from "@angular/router";
import { MsalGuard } from "@commonNodeModules/@azure/msal-angular";
import { AdminGuard } from "./guards/admin.guard";
import { MaintenanceActivateGuard } from "./guards/maintenance-activate.guard";
import { MaintenanceDeactivateGuard } from "./guards/maintenance-deactivate.guard";
import { TrackOnboardingGuard } from "./guards/track-onboarding.guard";

const routes: Routes = [
	{
		path: "",
		pathMatch: "full",
		loadComponent: () =>
			import('./public/home/home.component')
				.then(m => m.HomeComponent)
	},
	{
		path: "maintenance",
		pathMatch: "full",
		canActivate: [MaintenanceActivateGuard],
		canDeactivate: [MaintenanceDeactivateGuard],
		loadComponent: () =>
			import("./public/maintenance/maintenance.component").then(
				m => m.MaintenanceComponent
			)
	},
	{
		path: "a-z",
		loadComponent: () =>
			import("./public/az/az.component").then(
				m => m.AzComponent
			)
	},
	{
		path: "category",
		loadComponent: () =>
			import("./public/category/category.component").then(
				m => m.CategoryComponent
			)
	},
	{
		path: "international-catalogs",
		loadComponent: () =>
			import("./public/international-catalogs/international-catalogs.component").then(
				m => m.InternationalCatalogsComponent
			)
	},
	{
		path: "search",
		loadComponent: () =>
			import("./public/searchresult/searchresult.component").then(
				m => m.SearchResultComponent
			)
	},

	{
		path: "impressum",
		loadComponent: () =>
			import("./public/legalnotices/imprint/imprint.component").then(
				m => m.ImprintComponent
			)
	},
	{
		path: "rechtliche-hinweise",
		loadComponent: () =>
			import("./public/legalnotices/legal-note/legal-note.component").then(
				m => m.LegalNoteComponent
			)
	},
	{
		path: "datenschutz",
		loadComponent: () =>
			import("./public/legalnotices/data-protection/data-protection.component").then(
				m => m.DataProtectionComponent
			)
	},
	{

		matcher: catalogUrlMatcher,
		loadComponent: () =>
			import("./public/catalog/catalog.component").then(
				m => m.CatalogComponent
			)
	},
	{
		matcher: watchlistUrlMatcher,
		loadComponent: () =>
			import("./public/watchlist/watchlist.component").then(
				m => m.WatchlistComponent
			)
	},
	{
		path: 'initintegration',
		loadChildren: () =>
			import("./public/integration/integration-routing.module").then(
				m => m.routes
			)
	},
	{
		matcher: previewCatalogUrlMatcher,
		canActivate: [MsalGuard, AdminGuard],
		loadComponent: () =>
			import("./preview/preview-catalog/preview-catalog.component").then(
				m => m.PreviewCatalogComponent
			),
	},
	{
		path: "onboarding",
		canActivate: [TrackOnboardingGuard],
		loadComponent: () =>
			import("./public/onboarding/onboarding.component").then(
				m => m.OnboardingComponent
			)
	},
	{
		path: "administration",
		canActivate: [MsalGuard, AdminGuard],
		loadChildren: () =>
			import("./administration/administration-routing.module").then(
				m => m.routes
			)
	},
	{
		matcher: styleguideUrlMatcher,
		canActivate: [MsalGuard, AdminGuard],
		loadComponent: () =>
			import("./styleguide/styleguide/styleguide.component").then(
				m => m.StyleguideComponent
			)
	},
	{
		matcher: reloadUrlMatcher,
		loadComponent: () =>
			import("./public/errorpages/reload/reload.component").then(
				m => m.ReloadComponent
			)
	},

	{
		matcher: notFoundUrlMatcher,
		loadComponent: () =>
			import("./public/errorpages/page-not-found/page-not-found.component").then(
				m => m.PageNotFoundComponent
			)
	},



];

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function catalogUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	if (urlSegments.length < 2) {
		return null;
	}

	// Liste für verarbeitete URLSegmente
	const consumed = [];

	if (urlSegments[0].toString() === "catalog" && urlSegments[1]) {
		const params: { [name: string]: UrlSegment } = { key: urlSegments[1] };
		consumed.push(urlSegments[0]);
		consumed.push(urlSegments[1]);


		if (
			urlSegments[2] &&
			urlSegments[3] &&
			urlSegments[2].toString() === "catalogItem"
		) {
			consumed.push(urlSegments[2]);
			consumed.push(urlSegments[3]);
			params.catalogItemGuid = urlSegments[3];

			if (
				urlSegments[4] &&
				urlSegments[5] &&
				urlSegments[4].toString() === "attachment"
			) {
				consumed.push(urlSegments[4]);
				consumed.push(urlSegments[5]);
				params.attachmentId = urlSegments[5];
			}
		}

		if (urlSegments[urlSegments.length - 1].toString() === 'search') {
			params.view = urlSegments[urlSegments.length - 1];
			consumed.push(urlSegments[urlSegments.length - 1]);
		}
		return { consumed, posParams: params };
	}
	return null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function watchlistUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	// Liste für verarbeitete URLSegmente
	const consumed = [];

	if (urlSegments[0].toString() === "watchlist" && urlSegments[1]) {
		const params: { [name: string]: UrlSegment } = { watchlistSection: urlSegments[1] };
		consumed.push(urlSegments[0]);
		consumed.push(urlSegments[1]);

		let catalogItemInitial = 2;
		if (
			urlSegments[1].toString() === "userDefined") {
			catalogItemInitial = 3;
			if (urlSegments[2]) {
				consumed.push(urlSegments[2]);
				params.userDefined = urlSegments[2];
			}

		}

		if (
			urlSegments[catalogItemInitial] &&
			urlSegments[catalogItemInitial + 1] &&
			urlSegments[catalogItemInitial].toString() === "catalogItem"
		) {
			consumed.push(urlSegments[catalogItemInitial]);
			consumed.push(urlSegments[catalogItemInitial + 1]);
			params.catalogItemGuid = urlSegments[catalogItemInitial + 1];

			if (
				urlSegments[catalogItemInitial + 2] &&
				urlSegments[catalogItemInitial + 3] &&
				urlSegments[catalogItemInitial + 2].toString() === "attachment"
			) {
				consumed.push(urlSegments[catalogItemInitial + 2]);
				consumed.push(urlSegments[catalogItemInitial + 3]);
				params.attachmentId = urlSegments[catalogItemInitial + 3];
			}
		}
		if (
			urlSegments[2] &&
			urlSegments[3] &&
			urlSegments[2].toString() === "catalog"
		) {
			consumed.push(urlSegments[2]);
			consumed.push(urlSegments[3]);
			params.key = urlSegments[3];
		}
		return { consumed, posParams: params };
	}
	return null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function previewCatalogUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	if (urlSegments.length < 2) {
		return null;
	}

	// Liste für verarbeitete URLSegmente
	const consumed = [];

	if (urlSegments[0].toString() === "preview" && urlSegments[1]) {
		const params: { [name: string]: UrlSegment } = {
			key: urlSegments[1]
		};
		consumed.push(urlSegments[0]);
		consumed.push(urlSegments[1]);

		if (
			urlSegments[2] &&
			urlSegments[3] &&
			urlSegments[2].toString() === "catalogItem"
		) {
			consumed.push(urlSegments[2]);
			consumed.push(urlSegments[3]);
			params.catalogItemGuid = urlSegments[3];

			if (
				urlSegments[4] &&
				urlSegments[5] &&
				urlSegments[4].toString() === "attachment"
			) {
				consumed.push(urlSegments[4]);
				consumed.push(urlSegments[5]);
				params.attachmentId = urlSegments[5];
			}
		}
		if (urlSegments[urlSegments.length - 1].toString() === 'protocol' || urlSegments[urlSegments.length - 1].toString() === 'search') {
			params.view = urlSegments[urlSegments.length - 1];
			consumed.push(urlSegments[urlSegments.length - 1]);
		}
		return { consumed, posParams: params };
	}
	return null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function styleguideUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	if (urlSegments.length > 2) {
		return null;
	}

	// Liste für verarbeitete URLSegmente
	const consumed = [];

	if (urlSegments[0].toString() === "styleguide") {
		const params: { [name: string]: UrlSegment } = {};
		consumed.push(urlSegments[0]);
		if (urlSegments[1]) {
			params.content = urlSegments[1];
			consumed.push(urlSegments[1]);
		}

		return { consumed, posParams: params };
	}
	return null;
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function reloadUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	const consumed = [];
	// Wegen aggressiven caching muss bei Integrationen Seite neu geladen werden
	const excludeIntegrationpages = ['katalog', 'online', 'integration'];
	if (excludeIntegrationpages.includes(urlSegments[0].path)) {
		return { consumed };
	}
	if (urlSegments.some(segment => segment.path.includes('search.php'))) {
		consumed.push(urlSegments[0]);
		return { consumed };
	}

	return null;

}
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function notFoundUrlMatcher(urlSegments: UrlSegment[]): UrlMatchResult {
	const excludeEndings = ['.js', '.css', '.json', '.xml', '.worker'];
	if (excludeEndings.some(ending => urlSegments[urlSegments.length - 1].path.endsWith(ending))) {
		return undefined;
	}

	const consumed = [];
	consumed.push(urlSegments[0]);
	return { consumed };
}

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {})
	],
	exports: [RouterModule]
})

export class AppRoutingModule {
	constructor() { }
}
