import { CommonModule } from '@angular/common';
import { Component, ComponentRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { CatalogContentType } from '@enums/CatalogContentType';
import { CatalogViewCatalogInformationDto, DisplaySetting, ViewGroupItemDto, Viewer } from '@interfaces/HttpClient/CatalogApiPublicModels';

@Component({
	selector: 'app-catalog-content',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './catalog-content.component.html',
	styleUrls: ['./catalog-content.component.scss']
})
export class CatalogContentComponent implements OnInit, OnChanges {
	@ViewChild("viewer", { static: true, read: ViewContainerRef }) viewer: ViewContainerRef;
	@Input({ required: true }) type: CatalogContentType;
	@Input() catalog: CatalogViewCatalogInformationDto = undefined;
	@Input() mobileAnimationTime: number;
	@Input() isMobile: boolean;
	@Input() catalogViewShowPrice: DisplaySetting;
	@Output() openNews = new EventEmitter<number>();
	@Output() mobileBarClose = new EventEmitter();
	@Input() mainViewer: Viewer;
	@Input() attachment: ViewGroupItemDto;
	@Input() attachments: ViewGroupItemDto[];
	@Input() isSearchMode = false;
	private component: ComponentRef<any>;

	async ngOnInit() {
		switch (this.type) {

			case CatalogContentType.Tree: {
				const component = (await import('../../catalog/catalog-tree/catalog-tree.component')).CatalogTreeComponent;
				this.component = this.viewer.createComponent(component);
				break;
			}

			case CatalogContentType.SearchResult: {
				const component = (await import('../../catalog/catalog-searchresult/catalog-searchresult.component')).CatalogSearchresultComponent;
				this.component = this.viewer.createComponent(component);
				break;
			}

			case CatalogContentType.Footer: {
				const component = (await import('../../../components/footer/footer.component')).FooterComponent;
				this.component = this.viewer.createComponent(component);
				this.component?.setInput('toggleButtonPositionRight', 5);
				break;
			}

			case CatalogContentType.CatalogNotFound: {
				const component = (await import('../../errorpages/catalog-not-found/catalog-not-found.component')).CatalogNotFoundComponent;
				this.component = this.viewer.createComponent(component);
				break;
			}

			case CatalogContentType.NewsList: {
				const component = (await import('../../../components/news-list/news-list.component')).NewsListComponent;
				this.component = this.viewer.createComponent(component);
				this.setNewsListInput();
				break;
			}

			case CatalogContentType.OverviewViewer: {
				const component = (await import('../../../viewers/overview-viewer/overview-viewer.component')).OverviewViewerComponent;
				this.component = this.viewer.createComponent(component);
				this.setOverviewInput();
				break;
			}

		}

	}


	ngOnChanges(changes: SimpleChanges): void {
		switch (this.type) {
			case CatalogContentType.Tree: {
				break;
			}
			case CatalogContentType.SearchResult: {
				break;
			}
			case CatalogContentType.NewsList: {
				this.setNewsListInput();
				break;
			}
			case CatalogContentType.OverviewViewer: {
				this.setOverviewInput();
				break;
			}
		}
	}


	private setNewsListInput() {
		this.component?.setInput('catalogKey', this.catalog.key);
		this.component?.setInput('isMobile', this.isMobile);
		this.component?.instance.openNews.subscribe(newsCount => this.openNews.emit(newsCount));
	}

	private setOverviewInput() {
		this.component?.setInput('mainViewer', this.mainViewer);
		this.component?.setInput('attachment', this.attachment);
		this.component?.setInput('attachments', this.attachments);
		this.component?.setInput('isSearchMode', this.isSearchMode);
		this.component?.setInput('catalogViewShowPrice', this.catalogViewShowPrice);
	}


}
