import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { delayHelper } from "@app/helpers/delay-helper";
import { FolderViewerDto } from "@app/viewers/folder-viewer/folder-viewer.component";
import { isNullish } from "@commonHelpers/math-utils";
import { environment } from "@environments/environment";
import { globalEnvironment } from "@environments/globalEnvironment";
import { CatalogViewTreeItemType } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { INode } from "@interfaces/iNode";
import { firstValueFrom, lastValueFrom, of } from "rxjs";
import { CatalogViewService } from "./catalog-view.service";
import { RequestService } from "./request.service";

@Injectable()

export class CatalogTreeService {

	private currentViewTreeItemDto: any[];
	private static currentCatalogViewTreeItemDto: any[];
	private maxFolderItems = 100;
	private static loadingData = new EventEmitter<void>();
	private refreshTreeEmitter = new EventEmitter();
	private allNodes: INode[];
	private key: string;
	private static currentLoadingTreeKey: string;
	private treeEncoding = "br";
	private static newNodesSet = new EventEmitter();
	constructor(
		private requestService: RequestService,
		private httpClient: HttpClient,
		private catalogViewService: CatalogViewService
	) { }

	setKey(key: string) {
		this.key = key;
	}

	deleteTree() {
		this.key = undefined;
	}

	hasTree(): boolean {
		return this.key !== undefined;
	}

	public async setUpBaseTree() {
		const items = await this.getAllPublicCatalogItems();
		this.makeNodes(items)
	}

	async getAllPublicCatalogItems(): Promise<any[]> {
		try {
			if (this.key === CatalogTreeService.currentLoadingTreeKey) {
				return firstValueFrom(of(CatalogTreeService.currentCatalogViewTreeItemDto));
			} else {
				try {
					this.currentViewTreeItemDto = await firstValueFrom(this.httpClient.get<any[]>(this.getTreeUrl()));
				} catch {
					try {
						this.treeEncoding = "gzip";
						this.currentViewTreeItemDto = await firstValueFrom(this.httpClient.get<any[]>(this.getTreeUrl()));
					} catch {
					}
				}
				CatalogTreeService.currentLoadingTreeKey = this.key;
				CatalogTreeService.currentCatalogViewTreeItemDto = this.currentViewTreeItemDto
				return this.currentViewTreeItemDto
			}

		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public makeNodes(data: any[]) {
		this.allNodes = [];
		if (isNullish(data) || data?.length === 0) {
			return [];
		}
		const root = data?.[0] as any[];
		const rootItems = root?.[4] as any[];
		if (!isNullish(rootItems) && !isNullish(root)) {
			this.insertItems(rootItems, [+root[0]]);
		}
		this.setAllNodes(this.allNodes);
	}

	private insertItems(items: any[], ancestors: number[]) {
		for (const entry of items) {
			const node: INode = {
				id: +entry[0],
				text: entry[2],
				parentId: ancestors[ancestors.length - 1],
				type: entry[3],
				ancestors,
				guid: this.getGuid(entry[1])
			};
			this.allNodes.push(node);
			if (entry.length === 5) {
				const newAncestors = ancestors.concat([+entry[0]])
				this.insertItems(entry[4], newAncestors);
			}
		}
	}

	private getGuid(rawGuid: string) {
		return `${rawGuid.substring(0, 8)}-${rawGuid.substring(8, 12)}-${rawGuid.substring(12, 16)}-${rawGuid.substring(16, 20)}-${rawGuid.substring(20, 32)}`
	}

	private getTreeUrl() {
		return environment.blobUrl + globalEnvironment.treePath.replace('{key}', this.key).replace('{encoding}', this.treeEncoding)
	}

	async getFolderViewerData(catalogItemGuid: string, watchlistUserdefinedId?: number): Promise<FolderViewerDto> {
		if (isNullish(this.allNodes)) {
			await lastValueFrom(CatalogTreeService.loadingData)
		}
		const id = isNullish(watchlistUserdefinedId) ? this.allNodes?.find(node => node.guid === catalogItemGuid).id : watchlistUserdefinedId;
		const nodes = this.allNodes.filter(node => node.ancestors.includes(id));

		const positions = nodes.filter(node => node.type !== CatalogViewTreeItemType.Folder && node.type !== CatalogViewTreeItemType.FolderWithAttachments);

		return {
			itemGuids: positions.map(position => position.guid).slice(0, this.maxFolderItems),
			positions: positions.length,
			subdirectories: nodes.length - positions.length
		};
	}



	public async countCatalogItems(catalogItemId: number, catalogKey?: string): Promise<number> {
		await this.initializeCountCatalogItems(catalogKey)

		return this.searchItems(this.currentViewTreeItemDto, catalogItemId, 0);
	}

	public async countMultipleCatalogItems(catalogItemIds: number[], catalogKey?: string): Promise<number> {
		await this.initializeCountCatalogItems(catalogKey)

		return catalogItemIds.reduce((sum, catalogItemId) => sum + this.searchItems(this.currentViewTreeItemDto, catalogItemId, 0), 0)
	}

	private async initializeCountCatalogItems(catalogKey?: string) {
		// Bei Iframe muss der Tree nachgeladen werden.
		if (isNullish(this.currentViewTreeItemDto)) {
			const catalog = await this.catalogViewService.getCatalogInformation(catalogKey);
			if (!isNullish(catalog)) {
				this.setKey(catalogKey);
				await this.getAllPublicCatalogItems();
			}
		}
		// Export versuchen wenn Baum nicht geladen werden konnte;
		if (isNullish(this.currentViewTreeItemDto)) {
			return 0;
		}
	}

	private searchItems(items: any[], catalogItemId: number, countItem: number, activeCount?: boolean) {
		if (isNullish(items)) {
			return 0;
		}
		items.forEach(item => {
			if (+item[0] === catalogItemId) {
				activeCount = true
			}
			if (activeCount) {
				countItem++;

			}
			if (item.length === 5) {
				countItem = this.searchItems(item[4], catalogItemId, countItem, activeCount)
			}
			if (+item[0] === catalogItemId) {
				activeCount = false
			}

		})
		return countItem;
	}


	public getMaxFolderItems() {
		return this.maxFolderItems;
	}

	public refreshTreeSubscription(): EventEmitter<{ guid: string, id: number }> {
		return this.refreshTreeEmitter;
	}

	public refreshTree(guid: string, id: number) {
		delayHelper(() => this.refreshTreeEmitter.next({ guid, id }));
	}

	public setAllNodes(nodes: INode[]) {
		this.allNodes = nodes;
		CatalogTreeService.loadingData.next(null);
		CatalogTreeService.loadingData.complete();
		CatalogTreeService.newNodesSet.emit();
		CatalogTreeService.loadingData = new EventEmitter<void>();
	}

	public async getPositionGuidsByFolderGuid(catalogItemGuid: string) {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		const id = this.allNodes.find(node => node.guid === catalogItemGuid).id;
		const nodes = this.allNodes.filter(node => node.ancestors.includes(id));

		return nodes
			.filter(node => node.type !== CatalogViewTreeItemType.Folder && node.type !== CatalogViewTreeItemType.FolderWithAttachments)
			.map(node => node.guid);
	}

	public getIdFromGuid(guid: string) {
		return this.allNodes?.find(node => node.guid === guid)?.id;
	}

	public getNewNodesSubscription() {
		return CatalogTreeService.newNodesSet;
	}

	public async getPositionGuidsByCatalogKey(catalogKey: string) {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		const nodes = this.allNodes.filter(node => node.watchlistCatalogKey === catalogKey);
		return nodes
			.filter(node => node.type !== CatalogViewTreeItemType.Folder && node.type !== CatalogViewTreeItemType.FolderWithAttachments)
			.map(node => node.guid);
	}

	public async getPositionIdsById(id: number) {

		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		const nodes = this.allNodes.filter(node => node.parentId === id);
		return nodes
			.filter(node => node.type !== CatalogViewTreeItemType.Folder && node.type !== CatalogViewTreeItemType.FolderWithAttachments)
			.map(node => node.id);
	}

	public async getCurrentNode(params: ICatalogRoutingParams) {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		if (!isNullish(params.catalogItemGuid)) {
			return this.allNodes?.find(node => node.guid === params.catalogItemGuid);
		}

		if (!isNullish(params.userDefined)) {
			return this.allNodes?.find(node => node.id === +params.userDefined);
		}

		return this.allNodes?.[0];
	}

	public async getNodesByGuids(guids: string[]): Promise<INode[]> {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		return this.allNodes.filter(node => guids.some(guid => node.guid === guid))
	}

	public async getNodesByIds(ids: number[]): Promise<INode[]> {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		return this.allNodes.filter(node => ids.some(id => node.id === id))
	}

	public async getCatalogKeyByGuid(catalogItemGuid: string): Promise<string> {
		if (isNullish(this.allNodes)) {
			await firstValueFrom(CatalogTreeService.loadingData)
		}
		return this.allNodes?.find(node => node.guid === catalogItemGuid)?.watchlistCatalogKey;
	}

}