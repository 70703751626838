import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TrackLoginRequestParams } from "@app/models/tracking/track-event-param-type";
import { isNullish } from "@commonHelpers/math-utils";
import { MsalBroadcastService } from "@commonNodeModules/@azure/msal-angular";
import { InteractionStatus } from "@commonNodeModules/@azure/msal-browser";
import { LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { AccountService } from "@services/account.service";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { GlobalVarService } from "@services/global-var.service";
import { takeWhile } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class TrackOnboardingGuard {
	constructor(
		public activatedroute: ActivatedRoute,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private accountService: AccountService,
		private globalVarService: GlobalVarService,
		private msalBroadcastService: MsalBroadcastService,
	) { }

	async canActivate() {
		const onboardingIdFromUrl: string = await this.activatedroute.snapshot.queryParams?.onBoardingId;
		if (!isNullish(onboardingIdFromUrl) && !Number.isNaN(+onboardingIdFromUrl) && (+onboardingIdFromUrl) !== 0) {
			this.globalVarService.setOnboardingId(+onboardingIdFromUrl)
		}
		await this.msalBroadcastService.inProgress$.pipe(takeWhile(progress => progress !== InteractionStatus.None)).toPromise();
		if (!this.accountService.isLoggedIn()) {
			this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(LoginSource.OnBoard))
			await this.trackingFacadeService.commitAllTrackEvents();
			await this.accountService.login()
			return false;
		}
		return true;

	}

}
