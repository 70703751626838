<!-- <div *ngIf="show" class="overflow-hidden" @fadeIn @fadeOut> -->
<div class="alert orca-alert d-flex align-items-center me-4" [ngClass]="getClassByPositionType()">
	<ng-container [ngSwitch]="displayType">
		<div *ngSwitchCase="ALERT_DISPLAY_TYPES.INFO" class="mask-settings icon-mask-img information-mask-img bg-primary"></div>
		<div *ngSwitchCase="ALERT_DISPLAY_TYPES.SUCCESS" class="mask-settings icon-mask-img done-mask-img bg-success"></div>
		<div *ngSwitchCase="ALERT_DISPLAY_TYPES.WARNING" class="mask-settings icon-mask-img warning-mask-img bg-warning"></div>
		<div *ngSwitchCase="ALERT_DISPLAY_TYPES.DANGER" class="mask-settings icon-mask-img danger-mask-img bg-danger-medium"></div>
	</ng-container>
	<div class="ps-2">
		<ng-content></ng-content>
	</div>
</div>
<!-- </div> -->