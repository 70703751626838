import { Injectable, OnDestroy } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { environment } from '@environments/environment';
import { ApplicationInsights, IAutoExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { Subscription } from 'rxjs';
import { AuthenticatedUserService } from './authenticated-user.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class ApplicationInsightsService implements OnDestroy {

	private appInsights: ApplicationInsights;
	private sessionServiceSubscription: Subscription
	private userServiceSubscription: Subscription
	private authenticatedUserSubscription: Subscription

	constructor(private sessionService: SessionService, private userService: UserService, private authenticatedUserService: AuthenticatedUserService) {

	}

	ngOnDestroy(): void {
		this.sessionServiceSubscription?.unsubscribe()
		this.userServiceSubscription?.unsubscribe()
		this.authenticatedUserSubscription?.unsubscribe()
	}

	public init() {
		this.sessionServiceSubscription = this.sessionService.getAsObservable().subscribe(session => this.setSessionContext(session?.guid))
		this.userServiceSubscription = this.userService.getAsObservable().subscribe(user => this.setUserContext(user.guid))
		this.authenticatedUserSubscription = this.authenticatedUserService.getAsObservable().subscribe(authenticatedUserId => this.setAuthenticatedUserContext(authenticatedUserId))
		this.appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: environment.applicationInsightsSettings.instrumentationKey,
				isCookieUseDisabled: true,
				disableCookiesUsage: true,
				autoTrackPageVisitTime: false,
				enableAutoRouteTracking: false,
				enableCorsCorrelation: false, // !!! Unbedingt auf FALSE für CAD Forge Viewer
				enableRequestHeaderTracking: false,
				enableResponseHeaderTracking: false,
				enableAjaxErrorStatusText: true,
				enableAjaxPerfTracking: false,
				disablePageUnloadEvents: ["unload"],
				disableAjaxTracking: true,
				diagnosticLogInterval: 5000,		// 10.000
				loggingLevelConsole: 1,
				sessionRenewalMs: 60 * 60 * 1000,		// neue Session nach 30 Minuten Inaktivität
				sessionExpirationMs: (new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 23, 59, 0)).getTime()
					- (new Date()).getTime()
			}
		});
		this.appInsights.loadAppInsights();
	}

	public clearAuthenticatedUserContext() {
		this.appInsights?.clearAuthenticatedUserContext()
	}

	public flush() {
		this.appInsights?.flush();
	}

	public setAuthenticatedUserContext(localAccountId: string) {
		this.appInsights?.setAuthenticatedUserContext(localAccountId, "", true)
	}

	public setSessionContext(sessionGuid: string) {
		if (!isNullish(this.appInsights?.context)) {
			this.appInsights.context.session.id = sessionGuid;
		}
	}

	public setUserContext(userGuid: string) {
		if (!isNullish(this.appInsights?.context)) {
			this.appInsights.context.user.id = userGuid;
		}
	}

	public trackException(exception: Error | IAutoExceptionTelemetry, handledAt: string, severityLevel: number) {
		this.appInsights?.trackException({
			severityLevel,
			exception,
			properties: { url: handledAt }
		})
	}

	public hasInsights(): boolean {
		return !isNullish(this.appInsights);
	}

}
