import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LoadingComponent } from '../loading/loading.component';


@Component({
	selector: 'app-button',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgIf, NgStyle, NgClass, NgbTooltip, NgTemplateOutlet, RouterLink, LoadingComponent]
})
export class ButtonComponent implements OnInit {
	@Input() tooltip?;
	@Input() variant?: "outline-primary" | "outline-light" | "outline-dark" | "outline-dark-transparent" | "primary" = "outline-primary";
	@Input() size?: "large" | "small";
	@Input() block = false;
	@Input() disabled = false;
	@Input() width?;
	@Input() type?= "button";
	@Input() ariaLabel?;
	@Input() isLoading = false;
	@Input() isW100 = false;
	@Input() isH100 = false;
	@Input() hasMinWidth?= true;
	@Input() noPadding?= false;
	@Input() linkUrl;
	@Input() target = '';
	@Input() rel;
	@Input() buttonClasses?= [];
	@Input() routerLinkParams;
	@Input() queryLinkParams: any;
	@Output() clicked = new EventEmitter();

	constructor() { }

	ngOnInit(): void {
	}

	public get classes() {
		const classItems = {
			"btn-lg": this.size === "large",
			"btn-sm": this.size === "small",
			"btn-block": this.block,
			"min-btn-width": this.hasMinWidth,
			"no-padding": this.noPadding
		}

		return Object.keys(classItems).filter(key => classItems[key]).concat(this.disabled ? [] : this.buttonClasses);
	}

	handleClick(event: MouseEvent) {
		this.clicked.emit(event);
	}
}
