<address *ngIf="catalogInfo" class="d-flex flex-column bg-gray-300 p-4 contact-wrapper">
	<span class="title fw-bold text-larger">{{ catalogInfo.manufacturerName }}</span>
	<div class="mt-4">
		<app-button variant="primary" (clicked)="openContactForm()" tooltip="{{ 'global.contactform' | translate }}">
			<img src="assets/material/actionbuttons/ic_mail_outline_24px.svg" class="me-2" loading="lazy" alt="Kontaktformular" width="24px"
				height="24px">
			<span>{{ 'global.contact' | translate }}</span>
		</app-button>
	</div>
	<div class="d-flex flex-column mt-4">
		<div *ngIf="catalogInfo.streetAndNumber || catalogInfo.countryZipAndCity" class="d-flex mt-1 align-items-start">
			<div class="me-3">
				<img src="assets/orca/contact-info/room.svg" loading="lazy" alt="Adresse" width="20px" height="20px">
			</div>
			<div *ngIf="catalogInfo.streetAndNumber || catalogInfo.countryZipAndCity" class="d-flex flex-column">
				<div *ngIf="catalogInfo.streetAndNumber" class="text-break">
					{{ catalogInfo.streetAndNumber }}
				</div>
				<div *ngIf="catalogInfo.countryZipAndCity" class="text-break">
					{{ catalogInfo.countryZipAndCity }}
				</div>
			</div>
		</div>
		<div class="mt-2">
			<div *ngIf="catalogInfo.phone" class="d-flex align-items-center">
				<div class="me-3">
					<img src="assets/orca/contact-info/call.svg" loading="lazy" alt="Telefon" width="20px" height="20px">
				</div>
				<div *ngIf="catalogInfo.phone" class="d-flex flex-column">
					<div class="text-break">
						{{ catalogInfo.phone }}
					</div>
				</div>
			</div>
			<div *ngIf="catalogInfo.fax" class="d-flex align-items-center">
				<div class="me-3">
					<img src="assets/orca/contact-info/print.svg" loading="lazy" alt="Fax" width="20px" height="20px">
				</div>
				<div class="d-flex flex-column">
					<div class="text-break">
						{{ catalogInfo.fax }}
					</div>
				</div>
			</div>
		</div>
		<div class="mt-2">
			<div class="d-flex flex-column">
				<div *ngIf="catalogInfo.email1 || catalogInfo.email2" class="d-flex align-items-start">
					<div class="me-3">
						<img src="assets/orca/contact-info/ic_mail_outline.svg" loading="lazy" alt="Mail" width="18px" height="18px">
					</div>
					<div *ngIf="catalogInfo.email1 || catalogInfo.email2" class="d-flex flex-column">
						<div *ngIf="catalogInfo.email1">
							<span tabindex="0" (click)="openContactForm()" (keyup.enter)="openContactForm()"
								class="app-link-dark text-break line-height-medium" href="">
								{{ catalogInfo.email1 }}
							</span>
						</div>
						<div *ngIf="catalogInfo.email2">
							<a class="app-link-dark text-break line-height-medium" href="mailto://{{ catalogInfo.email2 }}"
								(click)="trackWebEmailEvent(catalogExternalViewType.Email2, catalogInfo.email2 )">
								{{ catalogInfo.email2 }}
							</a>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex flex-column">
				<div *ngIf="catalogInfo.webSite1 || catalogInfo.webSite2" class="d-flex align-items-start">
					<div class="me-3">
						<img src="assets/orca/contact-info/language.svg" alt="Website" width="18px" height="18px">
					</div>
					<div *ngIf="catalogInfo.webSite1 || catalogInfo.webSite2" class="d-flex flex-column">
						<div *ngIf="catalogInfo.webSite1">
							<a class="app-link-dark text-break line-height-medium" [href]="getLink(catalogInfo.webSite1)" target="_blank"
								rel="noreferrer"
								(click)="trackWebEmailEvent(catalogExternalViewType.WebSite1, getLink(catalogInfo.webSite1))">
								{{ catalogInfo.webSite1 }}
							</a>
						</div>
						<div *ngIf="catalogInfo.webSite2">
							<a class="app-link-dark text-break line-height-medium" [href]="getLink(catalogInfo.webSite2)" target="_blank"
								rel="noreferrer"
								(click)="trackWebEmailEvent(catalogExternalViewType.WebSite2, getLink(catalogInfo.webSite2))">
								{{ catalogInfo.webSite2 }}
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="d-flex mt-4" *ngIf="catalogInfo.socialMedia?.length > 0">
		<div *ngFor="let socialMedia of catalogInfo.socialMedia" class="me-2 mb-2">
			<a [href]="getLink(socialMedia.domain)" target="_blank" class="colored-on-hover d-inline-block" rel="noreferrer"
				(click)="trackSocialMediaEvent(socialMedia.socialMediaType, getLink(socialMedia.domain))">
				<img class="social-media-logo" loading="lazy" style="height: 1.8rem; width: 1.8rem;"
					[alt]="socialMediaType[socialMedia?.socialMediaType]" [src]="getSocialMediaLogo(socialMedia.socialMediaType)">
			</a>
		</div>
	</div>
</address>