<div class="position-relative">
	<div class="position-absolute header-navigation" (blur)="closeOnOutside($event)" tabindex="0">
		<div class="header-menu bg-white" [ngClass]="[currentStateClass, viewTypeClass]">
			<div class="d-flex h-100 flex-column justify-content-between">
				<div class="pt-4 ps-4 ms-1 ">
					<div *ngFor="let capture of model">
						<div class="fw-bold pb-2">{{'header-sidebar.'+capture.keypart+'.title'|translate}}</div>
						<div class="d-flex flex-column mt-2 ms-3 mb-3">
							<ng-container *ngFor="let link of capture.links">
								<a *ngIf="link.route && !isPreview" class="pb-2 link-navigation" (click)="closeDialog()"
									[routerLink]="link.route" [target]="link.target">
									{{'header-sidebar.'+capture.keypart+'.'+link.keypart|translate}}</a>
								<div *ngIf="link.route && isPreview" class="pb-2 link-navigation" (click)="showPreviewWarning()">
									{{'header-sidebar.'+capture.keypart+'.'+link.keypart|translate}}
								</div>
								<a *ngIf="link.href" class="pb-2 link-navigation" (click)="closeDialog()" rel="noreferrer"
									[target]="link.target" [href]="link.href">
									{{'header-sidebar.'+capture.keypart+'.'+link.keypart|translate}}</a>
								<div (click)="openCookieSettings()" class="pb-2 link-navigation cursor-pointer"
									*ngIf="link.customKey === 'cookie-settings'">
									{{'header-sidebar.'+capture.keypart+'.'+link.keypart|translate}}
								</div>
							</ng-container>
						</div>
					</div>
				</div>
				<div *ngIf="currentView === viewType.Desktop && !isInIntegrationMode"
					class="infoletter-container w-100 d-flex pb-4 flex-column align-items-center">
					<img src="/assets/orca/header/infoletter-anmelden.svg" style="width: 252px; height: 192px" class="p-3" alt="infoletter"
						loading="lazy" decoding="async">
					<div class="fw-bold mb-2">{{'header-sidebar.infoletter.planer'|translate}}</div>
					<app-button variant="primary" (clicked)="infoLetterClicked()" (mousedown)="loginMouseDown($event)"
						[linkUrl]="infoletterLink" target="_blank" rel="noreferrer">
						{{'header-sidebar.infoletter.login'|translate}}</app-button>
				</div>
			</div>
		</div>
	</div>
</div>