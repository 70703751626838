<header class="bg-primary" id="public-header">
	<div class="w-100 ps-4 pe-3">
		<div class="d-flex justify-content-between align-items-center">
			<div class="d-flex align-items-center">
				<img src="/assets/orca/header/burger-menu.svg" class="me-3 cursor-pointer"
					style="width: 2rem; height: 1.5rem; object-fit:contain" alt="burgermenu-navigation" loading="lazy" decoding="async"
					(mouseup)="headerBurgerMenu.triggerMenu()" (mousedown)="headerBurgerMenu.triggerMenuMouseDown()">
				<a [routerLink]="['/']" class="d-none d-md-block">
					<img src="assets/orca/logo.svg" alt="Orca logo" loading="lazy" decoding="async"
						style="height: 1.62rem; object-fit:scale-down; width: 15.72rem; ">
				</a>
				<a [routerLink]="['/']" class="d-md-none p-1">
					<img src="assets/orca/logo-small.svg" loading="lazy" decoding="async" alt="Orca logo small"
						style="height: 1.62rem; object-fit:scale-down; width: 2.3rem">
				</a>
			</div>
			<div class="ms-md-1 d-flex justify-content-end align-items-center">
				<div *ngIf="showWatchlist" class="pt-1 header-height">
					<app-watchlist-header-navigation>
					</app-watchlist-header-navigation>
				</div>
				<app-help-public></app-help-public>
				<app-user-menu-context [catalogKey]="catalogKey" target="_blank" rel="noopener" class="mx-3">
				</app-user-menu-context>
			</div>
		</div>
	</div>
</header>
<app-header-burger-menu #headerBurgerMenu class="h-100">
</app-header-burger-menu>