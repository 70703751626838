import { Directive, EventEmitter, Injector, Input, OnDestroy, Output } from "@angular/core";
import { isNullish } from "@commonHelpers/math-utils";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewType } from "@enums/view-type";
import { CatalogItemDetailsDto, CatalogLanguage, CatalogViewForeignLanguageCatalogsDto, CatalogViewGroupDto, CatalogViewRelatedCatalogsDto, DisplaySetting, FeatureToggleValuesDto, ViewGroup, ViewGroupDto, ViewGroupItemDto, Viewer } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogQueryParams, ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { IContactFormOpen } from "@interfaces/iContactFormOpen";
import { TabService } from "@services/tab.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription } from "rxjs";
import { CatalogViewServiceBase } from "./catalog-view-service-base";

export interface CatalogContentViewersViewModel {
	relatedCatalogs?: CatalogViewRelatedCatalogsDto[],
	foreignLanguageCatalogs?: CatalogViewForeignLanguageCatalogsDto[]
}
@Directive()
export abstract class CatalogDetailsBase implements OnDestroy {
	@Input() start: number;
	@Input() routingParams: ICatalogRoutingParams;
	@Input() queryParams: ICatalogQueryParams;
	@Input() catalogTitle: string;
	@Input() url: string;
	@Input() hasContactPersons: boolean;
	@Input() public catalogKey: string;
	@Input() featureToggles: FeatureToggleValuesDto;
	@Input() catalogViewForeignLanguageCatalogsDto: CatalogViewForeignLanguageCatalogsDto[];
	@Input() catalogViewRelatedCatalogsDto: CatalogViewRelatedCatalogsDto[];
	@Input() catalogDetailsType: CatalogDetailsType
	@Input() catalogViewShowPrice: DisplaySetting;
	@Output() contactFormOpen = new EventEmitter<IContactFormOpen>();
	@Input() catalogLanguage: CatalogLanguage;
	public viewGroupItems: ViewGroupItemDto[];
	public catalogItemDetailsDto: CatalogItemDetailsDto;
	public catalogContentViewersViewModel: CatalogContentViewersViewModel
	public tabsViewModel: ViewGroup[] = [];
	public hasThumbnails = false;
	// selected tap from current view
	public tab = ViewGroup;
	// data lists for viewing // see html
	public currentMainAttachment: ViewGroupItemDto;
	public tabNavId;
	public catalogItemId: number;
	public catalogItemGuid: string;
	public viewGroupDtos: ViewGroupDto[]
	public catalogInfoView: CatalogViewGroupDto;
	public catalogRelationsView: [CatalogViewRelatedCatalogsDto[], CatalogViewForeignLanguageCatalogsDto[]]
	public currentRoutingValues: ICatalogRoutingParams;
	public mainViewer: Viewer;
	protected currentQueryParamsValues: ICatalogQueryParams = {};
	public isInCatalogMode: boolean

	private subscription: Subscription
	public currentViewType: ViewType
	public viewType = ViewType

	get isCatalogCurrentlySelectedInTree() {
		return isNullish(this.currentRoutingValues?.catalogItemGuid);
	}

	public get isFolderCatalogItem() {
		return this.tabsViewModel?.includes(ViewGroup.OverviewFolder)
	}

	constructor(
		protected tabService: TabService,
		protected injector: Injector,
		protected viewTypeService: ViewTypeService
	) { }

	protected init() {
		this.subscription = this.viewTypeService.currentViewType$.subscribe(async currentViewType => {
			this.currentViewType = currentViewType
			if (this.isCatalogCurrentlySelectedInTree) {
				this.setTabs()
				await this.setViewer()
			}
		})
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe()
		this.destroy()
	}

	openContactForm(item: IContactFormOpen) {
		this.contactFormOpen.emit(item);
	}

	protected destroy() {
	}

	public getTabValue(): ViewGroup {
		return this.tabService.getTabValue(this.queryParams?.tab, this.tabsViewModel);
	}

	protected abstract setTabs();
	protected abstract setViewer();

	protected abstract loadAttachments(guid: string, catalogKey?: string);
	public abstract getCatalogViewService(): CatalogViewServiceBase;
}
