import { CommonModule } from '@angular/common';
import { Component, ComponentRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';
import { ActionButtonContentType } from '@enums/CatalogContentType';
import { CatalogDetailsType } from '@enums/catalog-details-type';
import { ExportSettingsDto, ViewGroupItemDto } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogRoutingParams } from '@interfaces/iCatalogRoutingParams';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-action-buttons-loading',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './action-buttons-loading.component.html',
	styleUrls: ['./action-buttons-loading.component.scss']
})
export class ActionButtonsLoadingComponent implements OnInit, OnChanges, OnDestroy {


	@Input({ required: true }) type: ActionButtonContentType;
	@ViewChild("viewer", { static: true, read: ViewContainerRef }) viewer: ViewContainerRef;
	@Input() exportSettings: ExportSettingsDto;
	@Input() selectedItem: ViewGroupItemDto;
	@Input() catalogKey: string;
	@Input() catalogDetailsType: CatalogDetailsType;
	@Input() routingParams: ICatalogRoutingParams;
	@Output() closeDialog = new EventEmitter()
	@Output() setPosition = new EventEmitter<{ height?: number, width?: number }>();
	private component: ComponentRef<any>;
	private static closeSubscription: Subscription;
	private static positionSubscription: Subscription;

	async ngOnInit() {
		switch (this.type) {
			case ActionButtonContentType.ExportDialog: {
				const component = (await import('../export-dialog/export-dialog.component')).ExportDialogComponent;
				this.component = this.viewer.createComponent(component);
				this.setExportDialogInput();
				break;
			}

			case ActionButtonContentType.CopyButton:
			case ActionButtonContentType.DownloadAndTextButton: {
				const component = (await import('../action-buttons-export/action-buttons-export.component')).ActionButtonsExportComponent;
				this.component = this.viewer.createComponent(component);
				this.setExportInput()
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		switch (this.type) {
			case ActionButtonContentType.ExportDialog: {
				this.setExportDialogInput();
				break;
			}
			case ActionButtonContentType.CopyButton:
			case ActionButtonContentType.DownloadAndTextButton: {
				this.setExportInput();
			}
		}
	}

	ngOnDestroy(): void {
		ActionButtonsLoadingComponent.closeSubscription?.unsubscribe();
		ActionButtonsLoadingComponent.positionSubscription?.unsubscribe();
	}

	private setExportDialogInput() {
		this.component?.setInput('catalogDetailsType', this.catalogDetailsType);
		this.component?.setInput('selectedItem', this.selectedItem);
		this.component?.setInput('parentInputKey', 'action-button');
		this.component?.setInput('catalogKey', this.catalogKey);
		this.component?.setInput('exportSettings', this.exportSettings);
		ActionButtonsLoadingComponent.closeSubscription?.unsubscribe();
		ActionButtonsLoadingComponent.positionSubscription?.unsubscribe();
		ActionButtonsLoadingComponent.closeSubscription = this.component?.instance.closeDialog.subscribe(() => this.closeDialog.emit());
		ActionButtonsLoadingComponent.positionSubscription = this.component?.instance.setPosition.subscribe(position => this.setPosition.emit(position));
	}

	private setExportInput() {
		this.component?.setInput('selectedItem', this.selectedItem);
		this.component?.setInput('routingParams', this.routingParams);
		this.component?.setInput('type', this.type);
	}

	public async triggerCopyToClipboard() {
		await this.component?.instance.onClipBoardClick()
	}

}
