import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, RouterModule } from '@angular/router';
import { animationFrameHelper } from '@app/helpers/animation-frame-helper';
import { isNullish } from '@commonHelpers/math-utils';
import { ViewGroup } from '@interfaces/HttpClient/CatalogApiPublicModels';

@Component({
	selector: 'app-mobile-bar',
	templateUrl: './mobile-bar.component.html',
	styleUrls: ['./mobile-bar.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule]
})

export class MobileBarComponent implements OnInit, OnChanges {

	@Input({ required: true }) mobileBarButtons: MobileButtonType[];
	@Input({ required: true }) initMobileBarButton: MobileButtonType;
	@Input() disabledMobileBarButtons: MobileButtonType[];
	@Output() buttonChanged = new EventEmitter<MobileButtonType>();
	private resizeObserver: ResizeObserver;
	public activeType: MobileButtonType;
	public mobileButtonType = MobileButtonType;
	public viewMobileBarButtons: MobileBarButtonDto[];
	public showMoreButton = false
	public showMoreButtons = false

	constructor(private route: ActivatedRoute, private elRef: ElementRef) { }


	ngOnInit(): void {
		if (isNullish(this.activeType)) {
			const mobileViewString: string = this.route.snapshot.queryParams.mobileView;
			this.activeType = isNullish(mobileViewString) ?
				this.initMobileBarButton :
				MobileButtonType[mobileViewString];
			if (!isNullish(mobileViewString)) {
				this.buttonChanged.emit(MobileButtonType[mobileViewString])
			}
		}



		this.route.queryParams.subscribe(params => {
			const mobileQueryParamString: string = params.mobileView;
			if (!isNullish(mobileQueryParamString)) {
				this.activeType = MobileButtonType[mobileQueryParamString]
			}
		});



		this.resizeObserver = new ResizeObserver(entries => {
			animationFrameHelper(entries, () => this.renderFooter())
		})

		this.resizeObserver.observe(this.elRef.nativeElement.querySelector('#mobileBarFooter'))

	}

	private renderFooter() {
		if (isNullish(this.mobileBarButtons)) {
			return;
		}
		const padding = 7;
		const gapSize = 7;
		const buttonSize = 51;
		const buttonLength = this.mobileBarButtons.length
		const footer = this.elRef.nativeElement.querySelector('#mobileBarFooter');
		const buttonDefaultLength = (buttonLength - 1) * gapSize + buttonLength * buttonSize + padding;
		const currentOffset = buttonDefaultLength - footer.offsetWidth;
		const defaultcountHiddenInScrollbar = Math.ceil(currentOffset / (gapSize + buttonSize)) + 1;
		const countHiddenInScrollbar = defaultcountHiddenInScrollbar >= 2 ? defaultcountHiddenInScrollbar : 0;
		this.viewMobileBarButtons = this.mobileBarButtons.map((button, idx) => ({
			type: button,
			maskImg: this.getMaskImg(button),
			queryParams: this.getQueryParams(button),
			routerLink: this.getRouterLink(button),
			disabled: this.disabledMobileBarButtons?.includes(button) ?? false,
			fitsInMobileBar: this.mobileBarButtons.length - idx > countHiddenInScrollbar,
			queryParamsHandling: this.getRouterLink(button)?.[0] !== '.' ?
				null : "merge"
		}))
		this.showMoreButton = countHiddenInScrollbar > 0;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!isNullish(changes.mobileBarButtons?.currentValue) || !isNullish(changes.disabledMobileBarButtons?.currentValue)) {
			this.renderFooter();
		}
	}

	private getMaskImg(type: MobileButtonType): any {
		switch (type) {
			case MobileButtonType.CatalogTree: return "catalog-tree-mask-img";
			case MobileButtonType.CatalogInfo: return "catalog-info-mask-img";
			case MobileButtonType.CatalogDetails: return "catalog-details-mask-img";
			case MobileButtonType.CatalogContact: return "catalog-contact-person-mask-img";
			case MobileButtonType.SearchInput: return "search-filter-mask-img";
			case MobileButtonType.Infocenter: return "news-mask-img";
			case MobileButtonType.Az: return "az-mask-img";
			case MobileButtonType.Category: return "category-mask-img";
			case MobileButtonType.Home: return "home-mask-img";
			case MobileButtonType.Search: return "search-mask-img";
			case MobileButtonType.SearchResult: return "search-result-mask-img";
			case MobileButtonType.InternationalCatalogs: return "international-catalogs-mask-img";
			case MobileButtonType.Watchlist: return "watchlist-mask-img";
			case MobileButtonType.WatchlistStructure: return "watchlist-structure-mask-img";
			case MobileButtonType.WatchlistUserdefined: return "watchlist-userdefined-mask-img";

		}
	}

	private getQueryParams(type: MobileButtonType): any {
		const queryParams = { mobileView: this.initMobileBarButton !== type ? MobileButtonType[type] : undefined }
		switch (type) {
			case MobileButtonType.CatalogTree:
			case MobileButtonType.SearchInput:
			case MobileButtonType.Infocenter:
			case MobileButtonType.Search:
			case MobileButtonType.SearchResult:
			case MobileButtonType.CatalogDetails: return queryParams;
			case MobileButtonType.CatalogInfo: return { tab: null, ...queryParams };
			case MobileButtonType.CatalogContact: return { tab: ViewGroup[ViewGroup.CatalogContactPersons], ...queryParams };
			case MobileButtonType.Watchlist:
			case MobileButtonType.WatchlistStructure:
			case MobileButtonType.WatchlistUserdefined:
			case MobileButtonType.Az:
			case MobileButtonType.Category:
			case MobileButtonType.InternationalCatalogs:
			case MobileButtonType.Home: return {}

		}
	}

	private getRouterLink(type: MobileButtonType): string[] {
		switch (type) {
			case MobileButtonType.CatalogTree:
			case MobileButtonType.SearchInput:
			case MobileButtonType.Infocenter:
			case MobileButtonType.CatalogInfo:
			case MobileButtonType.CatalogContact:
			case MobileButtonType.Search:
			case MobileButtonType.SearchResult:
			case MobileButtonType.CatalogDetails: return ['.'];
			case MobileButtonType.InternationalCatalogs: return ['/international-catalogs'];
			case MobileButtonType.Az: return ['/a-z'];
			case MobileButtonType.Category: return ['/category'];
			case MobileButtonType.Home: return ['/'];
			case MobileButtonType.Watchlist: return ['/watchlist', 'catalog'];
			case MobileButtonType.WatchlistStructure: return ['/watchlist', 'catalogAndStructure'];
			case MobileButtonType.WatchlistUserdefined: return ['/watchlist', 'userDefined'];
		}
	}



	buttonClicked(type: MobileButtonType) {
		this.activeType = type;
		this.buttonChanged.emit(type)
	}




}


export interface MobileBarButtonDto {
	maskImg: string;
	queryParams: MobileQueryParamsDto;
	routerLink: string[];
	type: MobileButtonType;
	disabled: boolean;
	queryParamsHandling: QueryParamsHandling
	fitsInMobileBar?: boolean;
}

export interface MobileQueryParamsDto {
	mobileView: string,
	tab?: string;
}

export enum MobileButtonType {
	Home = 0,
	Category = 1,
	Az = 2,
	InternationalCatalogs = 3,
	Search = 4,
	SearchInput = 5,
	SearchResult = 6,
	Infocenter = 7,
	CatalogTree = 8,
	CatalogInfo = 9,
	CatalogDetails = 10,
	CatalogContact = 11,
	Watchlist = 12,
	WatchlistStructure = 13,
	WatchlistUserdefined = 14
}
