<div class="action-buttons-height">
	<app-alert [positionType]="ALERT_POSITION_TYPES.ACTION_BUTTON_TOP"></app-alert>
	<section *ngIf="showActionButtons" id="action-buttons" class="d-flex gap-2">
		<!-- Action Buttons -->
		<ng-container *ngFor="let button of actionButtons">
			<app-button ngbDropdown #dropdown="ngbDropdown" [ariaLabel]="button.ariaLabel" dropdownClass="action-button-dropdown"
				class="disabled-toggle" container="body" (openChange)="openChange($event)" [routerLinkParams]="button.routerLinkParams"
				(clicked)="action(button.type, dropdown)" [target]="button.target" variant="outline-light" [hasMinWidth]="false"
				[ngbTooltip]="button.tooltipKey| translate" [class.downloading]="button.downloading" [disabled]="button.downloading"
				[openDelay]="button.openDelay">
				<div *ngIf="button.iconImg" class="icon {{button.iconImg}} bg-dark mask-settings">
				</div>
				<!-- Export Dialog Fenster -->
				<ng-container *ngIf="button.type === actionButtonType.Export">
					<div class="position-relative">
						<img src="assets/material/actionbuttons/save_alt-24px-1of2.svg" loading="lazy" alt="Export" width="24px"
							height="24px" />
						<img src="assets/material/actionbuttons/save_alt-24px-2of2.svg" loading="lazy" alt="Export" width="24px"
							height="24px" class="animated-export-img" />
					</div>
					<div ngbDropdownToggle role="button" aria-label="toggle export" class="no-dropdown-marker  dropdown-default-position"
						dropdown-desktop-position>
						<div class="dropdown-menu" ngbDropdownMenu>
							<div [style.max-height.px]="visibleDialogPosition.height" [ngClass]="[dropdownContainerClass]"
								[style.max-width.px]="visibleDialogPosition.width" class="overflow-auto">
								<app-action-buttons-loading [type]="actionButtonContentType.ExportDialog" #exportDialog
									*ngIf="dropdown.isOpen()" [catalogDetailsType]="catalogDetailsType" [selectedItem]="selectedItem"
									(setPosition)="setDialogPosition($event)" [catalogKey]="routingParams?.key"
									[exportSettings]="exportSettings" (closeDialog)="dropdown?.close()">
								</app-action-buttons-loading>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- Positionstext in Zwischenablage und  Zusatzdatei herunterladen dropdown-->
				<ng-container *ngIf="button.type === actionButtonType.CopyMenu">
					<div ngbDropdownToggle role="button" aria-label="toggle copyMenu"
						class="no-dropdown-marker position-relative dropdown-default-position">
						<div class="position-absolute" style="left:0">
							<div ngbDropdownMenu aria-labelledby="dropdownBasic3">
								<app-action-buttons-loading *ngIf="dropdown.isOpen()" [type]="actionButtonContentType.DownloadAndTextButton"
									[routingParams]="routingParams" [selectedItem]="selectedItem"></app-action-buttons-loading>
							</div>
						</div>
					</div>
				</ng-container>
				<!-- Positionstext in Zwischenablage kopieren-->
				<ng-container *ngIf="button.type === actionButtonType.CopyWindow">
					<app-action-buttons-loading #copyButtonLoading [type]="actionButtonContentType.CopyButton"
						[routingParams]="routingParams" [selectedItem]="selectedItem"></app-action-buttons-loading>
				</ng-container>
				<!-- Teilen-->
				<ng-container *ngIf="button.type === actionButtonType.Share">
					<div ngbDropdownToggle role="button" aria-label="toggle share"
						class="no-dropdown-marker position-relative dropdown-default-position">
						<div class="position-absolute" style="left:0">
							<div ngbDropdownMenu aria-labelledby="dropdownBasic2">
								<button *ngIf="copyToClipboardIsUsable" ngbDropdownItem (click)="onCopyLink()">
									<img src="assets/material/actionbuttons/link-24px.svg" loading="lazy"
										alt="{{ 'catalog-view.detail-actions.share.copy-link' | translate }}" width="24px" height="24px" />
									{{ 'catalog-view.detail-actions.share.copy-link' | translate }}
								</button>
								<a ngbDropdownItem (click)="onClickSendPerMail()" [href]="getEmailHref()">
									<img src="assets/material/actionbuttons/ic_mail_outline_24px.svg" loading="lazy"
										alt="{{ 'catalog-view.detail-actions.share.send-email.label' | translate }}" width="24px"
										height="24px" />
									{{ 'catalog-view.detail-actions.share.send-email.label' | translate }}
								</a>

							</div>

						</div>
					</div>
				</ng-container>
			</app-button>

		</ng-container>
		<!-- Kontaktformular-->
		<app-button *ngIf="!isWatchlistItem" [class.me-2]="currentViewType === viewType.Desktop" [hasMinWidth]="false" variant="primary"
			tooltip="{{ 'catalog-view.detail-actions.contact.tooltip' | translate }}" (clicked)="openContactForm()">
			<div style="white-space: nowrap;">
				<img src="assets/material/actionbuttons/ic_mail_outline_24px.svg" loading="lazy" alt="Kontaktformular" width="24px"
					height="24px">
				<span *ngIf="currentViewType === viewType.Desktop" class="ms-2 d-inline-block d-lg-none">{{
					'catalog-view.detail-actions.contact.label-short' | translate }}</span>
				<span class="ms-2 d-none" [ngClass]="['d-'+contactbuttonSize+'-inline-block']">{{
					'catalog-view.detail-actions.contact.label' | translate }}</span>
			</div>
		</app-button>
		<!-- Übernehmen (Softwareintegration)-->
		<app-button *ngIf="hasSoftwareIntegrationCallbackButton" (clicked)="onSoftwareIntegrationCallbackClick()" variant="primary"
			class="ms-auto">
			<div class="callback-button-height d-flex align-items-center">
				{{'catalog-view.detail-actions.software-integration-callback'|translate}}
			</div>
		</app-button>
		<!-- Übernehmenlink (Softwareintegration)-->
		<app-button *ngIf="hasCatalogItemLinkCallbackButton" (clicked)="onCatalogItemLinkCallbackClick()" variant="primary" class="ms-auto">
			<div class="callback-button-height d-flex align-items-center">
				{{'catalog-view.detail-actions.catalog-item-link-callback'|translate}}
			</div>
		</app-button>
	</section>
</div>