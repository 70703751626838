import { NgFor, NgIf } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { isNullish } from "@commonHelpers/math-utils";
import { AccountInfo } from "@commonNodeModules/@azure/msal-browser";
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { AccountLanguage, AdministrationDisplayInfoDto, UserMenuDisplayInfoDto } from "@interfaces/HttpClient/AccountApiPublicModels";
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";
import { AccountService } from "@services/account.service";
import { GlobalVarService } from "@services/global-var.service";
import { UserAuthorizationService } from "@services/user-authorization.service";
import { LanguageTranslationPipe } from "../../pipes/languageTranslation";
import { ButtonComponent } from "../button/button.component";
import { LoadingComponent } from "../loading/loading.component";
import { UserMenuResponsiveLinkComponent } from "./user-menu-responsive-link/user-menu-responsive-link.component";

@Component({
	selector: "app-user-menu",
	templateUrl: "./user-menu.component.html",
	styleUrls: ["./user-menu.component.scss"],
	standalone: true,
	imports: [NgIf, LoadingComponent, UserMenuResponsiveLinkComponent, ButtonComponent, NgFor, NgbTooltip, TranslateModule, LanguageTranslationPipe]
})
export class UserMenuComponent implements OnInit {
	public model: UserMenuDisplayInfoDto;
	public name: string;
	public initials: string;
	public loaded = false;
	private validKey = true;
	private account: AccountInfo;
	public email: string;

	@Input() catalogId: number;
	@Input() companyId: number;
	@Input() catalogKey: string;
	@Input() target: string;
	@Input() rel: string;
	@Output() closeMenu = new EventEmitter();
	@Output() login = new EventEmitter();
	@Output() logout = new EventEmitter();

	constructor(
		private userAuthorizationService: UserAuthorizationService,
		private accountService: AccountService,
		private globalVarService: GlobalVarService
	) {
	}

	async ngOnInit() {
		if (this.isLoggedIn) {
			this.account = this.accountService.getAccount();
			if (isNullish(this.model)) {
				this.model = await this.getModel();
			}
			await this.handleModel();
		}
	}

	private async handleModel() {
		const claims = this.account?.idTokenClaims as any;
		this.email = this.account?.username;
		this.name = `${claims?.given_name} ${claims?.family_name}`
		this.initials = (claims?.given_name?.[0] ?? '') + (claims?.family_name?.[0] ?? '')?.toUpperCase();
		this.loaded = true;
	}

	private async getModel(): Promise<UserMenuDisplayInfoDto> {
		if (this.validKey) {
			if (!isNullish(this.catalogId)) {
				return this.userAuthorizationService.getMenuDisplayInfoDtoByCatalogId(this.catalogId);
			}
			if (!isNullish(this.companyId)) {
				return this.userAuthorizationService.getMenuDisplayInfoDtoByCompanyId(this.companyId);
			}
			if (!isNullish(this.catalogKey)) {
				return this.userAuthorizationService.getMenuDisplayInfoDtoByCatalogKey(this.catalogKey);
			}
		}

		return this.userAuthorizationService.getMenuDisplayInfo();
	}

	public isCatalogActive(catalog: AdministrationDisplayInfoDto) {
		if (!isNullish(this.catalogId)) {
			return catalog.companyOrCatalogId === this.catalogId;
		}
		if (!isNullish(this.catalogKey)) {
			return catalog.catalogKey === this.catalogKey;
		}
	}

	public get isLoggedIn(): boolean {
		return this.accountService.isLoggedIn();
	}

	public get accountLanguage() {
		return this.globalVarService.getLanguage() ?? AccountLanguage.German;
	}

}
