<div *ngIf="viewGroupItems" class="h-100  scrollbox-autohover">
	<div #thumbnails class="d-flex flex-row scrollbox-content-autohover align-items-center">
		<a [routerLink]="[getRouterLink(viewGroupItem)]" queryParamsHandling="merge"
			*ngFor="let viewGroupItem of viewGroupItems; let isFirst = first" class="thumbnail-wrapper cursor-pointer img-wrapper m-2 mb-3"
			[ngClass]="{'selected-item': selectedItem && viewGroupItem.id === selectedItem.id, 'first-item': isFirst}">
			<div>
				<img [src]="viewGroupItem.thumbnailUrl" width="70" height="70" loading="lazy" style="object-fit: scale-down;"
					[alt]="viewGroupItem.title" onerror="this.src='assets/material/help-24px.svg'" class="thumbnail" />
			</div>
		</a>
	</div>
</div>