<footer id="mobileBarFooter"
	class="bg-gray-200 w-100 d-flex align-items-center justify-content-between mobile-bar border-gray-400 border-top">
	<div class="d-flex gap-2  ps-3">
		<ng-container *ngTemplateOutlet="buttonsTemplate; context: { fit: true }">
		</ng-container>
	</div>
	<div *ngIf="showMoreButton" class="rounded position-relative m-1" [class.bg-primary]="showMoreButtons">
		<div [class] class="show-more-img mask-settings bg-dark button-size border" (click)="showMoreButtons=!showMoreButtons">
		</div>
		<div *ngIf="showMoreButtons" class="position-absolute bg-gray-200 p-1 border d-flex button-position">
			<ng-container *ngTemplateOutlet="buttonsTemplate; context: { fit: false }">
			</ng-container>
		</div>
	</div>
</footer>

<ng-template #buttonsTemplate let-fit="fit">
	<ng-container *ngFor="let button of viewMobileBarButtons">
		<ng-container *ngIf="!fit &&!button.fitsInMobileBar || fit &&button.fitsInMobileBar">
			<a *ngIf="!button.disabled" [routerLink]="button.routerLink" (click)="buttonClicked(button.type)"
				[attr.aria-label]="mobileButtonType[button.type]" [queryParams]="button.queryParams"
				[queryParamsHandling]="button.queryParamsHandling" class="rounded" [class.bg-primary]="activeType === button.type">
				<div class="{{button.maskImg}} mask-settings bg-dark button-size border mobile-button">
				</div>
			</a>
			<div *ngIf="button.disabled" class="rounded">
				<div class="{{button.maskImg}} mask-settings bg-gray-400 button-size border mobile-button">
				</div>
			</div>
		</ng-container>
	</ng-container>
</ng-template>