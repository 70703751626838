import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from '@app/animations/animations';
import { ALERT_DISPLAY_TYPES, ALERT_POSITION_TYPES } from '@services/alert.service';
import { NgClass, NgSwitch, NgSwitchCase } from '@angular/common';

@Component({
    selector: 'app-alert-ui',
    templateUrl: './alert-ui.component.html',
    styleUrls: ['./alert-ui.component.scss'],
    animations: [
        fadeIn,
        fadeOut
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgClass, NgSwitch, NgSwitchCase]
})
export class AlertUiComponent implements OnInit {

	ALERT_DISPLAY_TYPES = ALERT_DISPLAY_TYPES
	ALERT_POSITION_TYPES = ALERT_POSITION_TYPES

	@Input() positionType?: ALERT_POSITION_TYPES
	@Input() displayType: ALERT_DISPLAY_TYPES

	constructor() { }

	ngOnInit(): void { }

	getClassByPositionType() {
		switch (this.positionType) {
			case ALERT_POSITION_TYPES.GLOBAL:
				return 'orca-alert-global'
			case ALERT_POSITION_TYPES.BUTTON_COPY_VIDEO_LINK:
				return 'orca-alert-tooltip'
			case ALERT_POSITION_TYPES.ACTION_BUTTON_TOP:
				return 'orca-alert-actionbuttons-top'
			default:
				return 'orca-alert-full-width'
		}
	}
}
