import { Injectable } from '@angular/core';
import { HTTPStatusCode } from '@enums/statusCodes';
import { Client as CatalogPublicApi, WatchListCheckDto, WatchListDto, WatchListFlatDto, WatchListInformationDto, WatchListStructure } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { AccountService } from './account.service';
import { RequestService } from './request.service';

@Injectable({
	providedIn: 'root'
})
export class WatchlistService {

	constructor(
		private accountService: AccountService,

		private catalogPublicApi: CatalogPublicApi,
		private requestService: RequestService
	) { }

	private static isWatchlistUpating = false;

	public async addCatalogItemToWatchList(catalogItemGuid: string): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			WatchlistService.isWatchlistUpating = true;
			return await this.catalogPublicApi.watchList_AddCatalogItemToWatchList(catalogItemGuid).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		} finally {
			WatchlistService.isWatchlistUpating = false;
		}
	}

	public async removeCatalogItemFromWatchList(catalogItemGuid: string): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			WatchlistService.isWatchlistUpating = true;
			return await this.catalogPublicApi.watchList_RemoveCatalogItemFromWatchList(catalogItemGuid).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		} finally {
			WatchlistService.isWatchlistUpating = false;
		}
	}

	public async checkForRemoveIfCatalogItemIsInUserDefinedList(catalogItemGuid: string): Promise<WatchListCheckDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_CheckForRemoveIfCatalogItemIsInUserDefinedList(catalogItemGuid).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async checkForRemoveIfCatalogItemsAreInUserDefinedListByCatalogId(catalogId: number): Promise<WatchListCheckDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_CheckForRemoveIfCatalogItemsAreInUserDefinedListByCatalogId(catalogId).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}



	public async getWatchListAsFlatList(): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_GetWatchListAsFlatList().toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async addPositionsToWatchList(currentWatchlistItems: string[]): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			WatchlistService.isWatchlistUpating = true;
			return await this.catalogPublicApi.watchList_AddPositionsToWatchList(currentWatchlistItems).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		} finally {
			WatchlistService.isWatchlistUpating = false;
		}
	}

	public async getWatchListInformation(): Promise<WatchListInformationDto> {
		try {
			return await this.catalogPublicApi.watchList_GetWatchListInformation().toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async removeAllCatalogItemsFromWatchList(): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			WatchlistService.isWatchlistUpating = true;
			return await this.catalogPublicApi.watchList_RemoveAllCatalogItemsFromWatchList().toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		} finally {
			WatchlistService.isWatchlistUpating = false;
		}
	}

	public async getWatchListStructure(structureInfo?: WatchListStructure): Promise<WatchListDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_GetWatchListStructure(structureInfo).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async getWatchListStructureFromEveryone(catalogItemGuids?: string[], structureInfo?: WatchListStructure): Promise<WatchListDto> {
		try {
			return await this.catalogPublicApi.watchList_GetWatchListStructureFromEveryone(catalogItemGuids, structureInfo).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async removeAllUnavailableCatalogItemsFromWatchList(): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_RemoveAllUnavailableCatalogItemsFromWatchList().toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async removeCatalogItemsFromWatchListByCatalogId(catalogId: number): Promise<WatchListFlatDto> {
		try {
			if (this.reloadIfNotLogin()) {
				return null;
			}
			return await this.catalogPublicApi.watchList_RemoveCatalogItemsFromWatchListByCatalogId(catalogId).toPromise();
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HTTPStatusCode.NoContent, return: undefined }
			]);
		}
	}



	public setWatchlistUpdating(value: boolean) {
		WatchlistService.isWatchlistUpating = value;
	}

	public getWatchlistUpdating() {
		return WatchlistService.isWatchlistUpating;
	}

	private reloadIfNotLogin() {
		if (!this.accountService.isLoggedIn()) {
			location.reload();
			return true
		}
		return false;
	}












}
