import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ViewType } from '@enums/view-type';
import { ConfirmDialogService } from '@services/confirm-dialog.service';
import { TranslationService } from '@services/translation.service';
import { ViewTypeService } from '@services/view-type.service';
import { Subscription } from 'rxjs';


@Component({
	selector: 'app-user-menu-responsive-link',
	templateUrl: './user-menu-responsive-link.component.html',
	styleUrls: ['./user-menu-responsive-link.component.scss'],
	standalone: true,
	imports: [NgIf, RouterLink, NgTemplateOutlet]
})
export class UserMenuResponsiveLinkComponent implements OnInit, OnDestroy {
	@Input() link: (string | number)[];
	@Input() rel: string;
	@Input() styleClass: string;
	@Input() target: string;
	@Input() direction: string;
	@Output() close = new EventEmitter();
	public viewType = ViewType;
	private currentViewType$ = this.viewTypeService.currentViewType$;
	public currentViewType: ViewType
	private keyPrefix = "user-menu.responsive-admin-menu."
	private subscription: Subscription

	constructor(
		private confirmDialogService: ConfirmDialogService,
		private translationService: TranslationService,
		private router: Router,
		private viewTypeService: ViewTypeService,
		private cdRef: ChangeDetectorRef,
	) { }


	ngOnInit(): void {
		this.subscription = this.currentViewType$.subscribe(currentViewType => {
			this.currentViewType = currentViewType
			this.cdRef.detectChanges();

		})
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe();
	}

	closeMenu(): void {
		this.close.emit();
	}

	dialogNavigate(viewType: ViewType) {
		this.closeMenu()
		if (viewType !== ViewType.Desktop) {
			this.confirmDialogService.open({
				confirm: `${this.keyPrefix}confirm`,
				title: `${this.keyPrefix}title`,
				description: `${this.keyPrefix}description`,
				reject: `${this.keyPrefix}reject`,
				descriptionVars: { direction: this.translationService.getByKey(`${this.keyPrefix}direction.${this.direction}`) }
			},
				() => {
					const url = this.router.createUrlTree(this.link)
					window.open(url.toString(), this.target)
				}, () => {

				})
		}

	}

}
