<div class="h-100">
	<div *ngIf="catalogInfo else loading" class="d-flex justify-content-between">
		<div class="w-100 me-3">
			<article class="mt-4 catalog-text" *ngIf="catalogInfo.catalogDescription">
				{{catalogInfo.catalogDescription}}
			</article>
			<dl class="mt-4 d-grid grid-counts align-items-center mb-2">
				<ng-container *ngFor="let item of countValues">
					<dt class="line-height-medium fw-normal key-title ">{{ item.translationKey| translate }}</dt>
					<dd class="line-height-medium d-block badge badge-pill badge-numbers min-content ms-auto mb-0">{{item.count|
						numberFormat
						}}
					</dd>
				</ng-container>
			</dl>
			<dl class="d-flex  pb-2 pt-3">
				<dt class="text-small fw-normal key-title pe-1">{{ 'catalog-view.last-update' | translate }}</dt>
				<dd class="text-small">{{catalogInfo.lastUpdate | date: ('global.date-format-short'| translate)}}</dd>
			</dl>

			<dl class="pb-2 mb-2" *ngIf="additionalCategoriesTexts?.length">
				<dt class="fw-bold key-title">{{ 'catalog-view.categories-headline' | translate }}</dt>
				<dd>
					<ul class="ps-3">
						<li *ngFor="let category of additionalCategoriesTexts" class="pt-2">{{ category }}</li>
					</ul>
				</dd>
			</dl>
		</div>
		<app-catalog-contact *ngIf="currentViewType === viewType.Desktop" class="w-100 " [catalogInfo]="catalogInfo"
			[catalogKey]="catalogKey" (contactFormOpen)="openContactForm($event)">
		</app-catalog-contact>
	</div>
</div>

<ng-template #loading>
	<div class="h-100 w-100 d-flex justify-content-center align-items-center">
		<app-loading></app-loading>
	</div>
</ng-template>